<template>
    <div class="flex flex-col  items-center gap-6 ">
        <div class=" w-3/4  md:w-1/2 mt-6 ">
            <SearchSection />
        </div>
        <div class="w-3/4 ">
            <StarsSection />
            <ShopsSection />
            <CategoriesSection />
            <ServicesSection />
        </div>
    </div>
</template>

<script>
import StarsSection from './sections/StarsSection.vue';
import CategoriesSection from './sections/ProductsCategoriesSection.vue';
import ServicesSection from './sections/ServicesCategoriesSection.vue'
import ShopsSection from './sections/ShopsSection.vue';
import SearchSection from './sections/SearchSection.vue';
export default {
    components: {
        StarsSection,
        CategoriesSection,
        ServicesSection,
        ShopsSection,
        SearchSection
    }
}
</script>