import { defineStore } from 'pinia';
import api from '@/api';

export const useNotificationStore = defineStore({
    id: 'notification',

    state: () => ({
        notifications: [],
        isOpen:false,
        error: null,
        loading: false,
    }),
    actions: {
        async fetchAllNotifications() {
            this.notifications = [];
            this.error = null
            this.loading = true;
            try {
                const notifications = await api.getAllNotifications();
                this.notifications = notifications.data;
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        toggleNotificationList() {
            if (this.isOpen) {
                this.isOpen = false
            } else {
                this.fetchAllNotifications();
                this.isOpen = true;
            }
        }

    },
    
})



