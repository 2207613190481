import SignupForm from "./../views/signup/SignupForm";
import ResetPasswordForm from "./../views/resetPassword/ResetPasswordForm";
import UpdateShopForm from "./../views/updateShop/UpdateShopForm";
import LoginForm from "./../views/login/LoginForm";
import HomePage from "./../views/home/HomePage";
import ItemsPage from "./../views/items/ItemsPage";
import ShopPage from '@/views/shop/ShopPage';
import MyShopPage from '@/views/myShop/MyShopPage';
import ShowProduct from '@/views/product/ShowProduct';
import AddProduct from '@/views/product/AddProduct';
import AddService from '@/views/service/AddService';
import ShowService from '@/views/service/ShowService';
import UpdateService from '@/views/service/UpdateService';
import UpdateProduct from '@/views/product/UpdateProduct'
import ContactWithUs from '@/views/contact/ContactWithUs'
const routes = [
    {
        path: "/login",
        name: "Login",
        component: LoginForm,
    },
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/signup",
        name: "signup",
        component: SignupForm,
    },
    {
        path: "/myShop/update",
        name: "update",
        component: UpdateShopForm,
    },
    {
        path: "/items",
        name: "items",
        component: ItemsPage,
    },
    {
        path: "/shop/:id",
        name: "shop",
        component: ShopPage,
    },
    {
        path: "/myShop",
        name: "myShop",
        component: MyShopPage,
    },
    {
        path: "/product/:id",
        name: "product",
        component: ShowProduct,
    },
    {
        path: "/product/update/:id",
        name: "updateProduct",
        component: UpdateProduct,
    },
    {
        path: "/service/:id",
        name: "service",
        component: ShowService,
    },    {
        path: "/service/update/:id",
        name: "updateService",
        component: UpdateService,
    },
    {
        path: "/product/add",
        name: "AddProduct",
        component: AddProduct,
    },
    {
        path: "/service/add",
        name: "AddService",
        component: AddService,
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactWithUs,
    },
    {
        path: "/reset",
        name: "reset",
        component: ResetPasswordForm,
    },
];

export default routes