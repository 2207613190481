<template>
    <div class="flex flex-col gap-5 justify-center   ">
        <div>
            <TextField id="phone" :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
                placeholder="رقم الهاتف" v-model="phoneNumber" />
        </div>
        <div class="w-full flex justify-center items-center" >
            <StanderButton :disabled="false" :onClick="nextStep"
                class=" w-1/2  text-sm sm:text-base self-center bg-yellow-primary hover:bg-yellow-dark ">
                التالي
            </StanderButton>
            <LoadingCircle v-if="loading && !error" class="h-8" />

        </div>

        <div class=" flex items-center gap-5 md:gap-10 mt-16 self-center ">
            <router-link to="/login">
                <StanderButton class="bg-gray-900 hover:bg-black text-gray-100 text-sm sm:text-base px-5 md:px-16">
                    تسجيل الدخول
                </StanderButton>
            </router-link>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { phoneNumberRule, requiredRule } from '../../../assets/validation'
import { storeToRefs } from 'pinia';
import StanderButton from "@/components/buttons/StanderButton.vue";
import LoadingCircle from "@/components/response/LoadingCircle.vue";

export default {
    components: { TextField, StanderButton, LoadingCircle },
    setup() {
        const authStore = useAuthStore();
        return {
            sendOtp: authStore.resetPasswordSendOtp,
            phoneNumber: storeToRefs(authStore).phoneNumber,
            loading: storeToRefs(authStore).loading,
            error: storeToRefs(authStore).error,
        };
    },
    data() {
        return {
            errorMessage: {
                phoneNumber: [],
            },
            isValid: {
                phoneNumber: false,
            }
        }
    },

    methods: {
        async nextStep() {
            this.validatePhoneNumber(this.phoneNumber);
            if (this.isValid.phoneNumber) {
                await this.sendOtp();
                this.error ? this.errorMessage.phoneNumber.push('تاكد من رقم الهاتف') : this.$emit('changeStep', 2)
            }
        },

        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
    },
};
</script>