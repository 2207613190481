<template>
    <div>
        <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700">
            {{ label }}
        </label>
        <div class="relative">
            <textarea :id="id" :rows="rows" :cols="cols" autocomplete="off" :disabled="disabled" valid="false" :value="modelValue"
                @input="onInput" @blur="checkValidate" :placeholder="placeholder" :class="[
                    extendClasses,
                    'form-input block w-full py-2 px-3 border border-gray-600 bg-white text-gray-900 rounded-xl shadow-sm focus:outline-none     focus:border-gray-900 sm:text-sm',
                    !validationErrors.length || ('border-red-danger focus:border-red-danger')]" />
            <div v-if="maxLength"
                class=" w-full text-left  pointer-events-none text-gray-400 text-sm">
                {{ modelValue.length }} / {{ maxLength }}
            </div>
        </div>
        <p v-for="validationError in validationErrors" :key="validationError" class="mx-3 mt-1  text-sm text-red-danger">
            *{{ validationError }}
        </p>
    </div>
</template>
  
<script>
export default {
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
        rows: {
            type: String,
            required: false,
            default: "5",
        },
        cols: {
            type: String,
            required: false,
            default: "",
        },
        maxLength: {
            type: [Number, String],
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        extendClasses: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        validate: {
            type: Function,
            required: true,
            default: () => { },
        },
        validationErrors: {
            type: Array,
            required: true,
            default: new Array(),
        }
    },
    methods: {
        onInput(event) {
            const maxLength = parseInt(this.maxLength, 10);
            if (maxLength && event.target.value.length > maxLength) {
                event.target.value = event.target.value.slice(0, maxLength);
            }
            this.$emit("update:modelValue", event.target.value);
        },
        checkValidate(event) {
            this.validate(event.target.value);
        },
    },

};
</script>