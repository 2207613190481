import axios from 'axios';
import { useAuthStore } from '@/stores/authStore'
const instance = axios.create({
  baseURL: 'https://api.binna-mall.com/api',
});
instance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const token = authStore.authToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authStore = useAuthStore();
    if (error.response && error.response.status == 401) {
      authStore.clearAuthToken()
      window.location.reload();
    }
    return Promise.reject(error);
  }
);


export default instance;
