<template>
    <router-link :to="'/shop/'+ id">
        <div class="flex flex-col rounded-3xl overflow-hidden shadow-lg">
            <img class=" aspect-[21/9] object-contain overflow-hidden" alt="shop Image" :src="imageSrc">
            <div
                class="flex-auto bg-gray-900 text-gray-300    flex flex-col justify-center items-start text-center px-3 py-2 ">
                <h1 class=" text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl"> {{ truncatedShopName }}</h1>
                <h1 class=" text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl "> {{ truncatedLocation }}</h1>
            </div>
        </div>
    </router-link>
</template>
 
<script >



export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
            default: "",
        },
        shopName: {
            type: String,
            required: true,
            default: "",
        },
        location: {
            type: String,
            required: true,
            default: "",
        },
        id: {
            type: [Number, String],
            required: true,
        },

    },

    computed: {
    truncatedLocation() {
      return this.truncateText(this.location , 3);
    },
    truncatedShopName() {
      return this.truncateText(this.shopName, 3);
    }
  },
  methods: {
    truncateText(text, maxWords ) {
      const words = text.split(" ");
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(" ") + "...";
      } else {
        return text;
      }
    }
  }

}
</script>
