<template>
    <div
        class=" flex justify-center items-center lg:justify-start  bg-[url('@/assets/images/aboutusBg.jpg')] h-[calc(100vh_-_4rem)] w-screen bg-cover overflow-hidden	 ">
        <div
            class="h-full w-full sm:w-8/12 md:w-7/12 lg:w-5/12 2xl:w-4/12  opacity-100 px-10 flex flex-col gap-2   justify-center items-center  ">
            <img src="@/assets/icons/logoWithArabicName.svg" alt="logo with arabic name" class="pb-10">
            <div v-for="phoneNumber in phoneNumbers" :key="phoneNumber" class="w-full ">
                <div @click="() => { copyText(phoneNumber) }"
                    class="bg-white hover:bg-yellow-dark transition-all duration-150 w-full rounded-full py-1 sm:py-2 flex items-center px-5 cursor-pointer ">
                    <div class="w-1/6 md:w-2/6">الهاتف</div>
                    <div class="w-3/6 md:2/6">{{ phoneNumber }}</div>
                    <div class="w-2/6 flex justify-end items-center gap-3">
                        <span >نسخ الرقم</span>
                        <img src="@/assets/icons/phone.svg" alt="phone Icon">
                    </div>
                </div>
            </div>


            <a :href="'https://www.facebook.com'" target="_blank"
                class="bg-white hover:bg-yellow-dark transition-all duration-150  w-full rounded-full py-1 sm:py-2 flex items-center px-5">
                <div class=" w-3/6 sm:w-2/6"> حساب الفيسبوك</div>
                <div class="w-1/6 sm:w-2/6"></div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/facebookOutlineDark.svg" alt="facebook icon">
                </div>
            </a>
            <a :href="'https://www.instagram.com'" target="_blank"
                class="bg-white hover:bg-yellow-dark transition-all duration-150  w-full rounded-full p-1 flex items-center px-5 ">
                <div class=" w-3/6 sm:w-2/6  ">حساب الانستكرام</div>
                <div class="w-1/6 sm:w-2/6"></div>
                <div class=" w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/instaOutlineDark.svg" alt="insta icon">
                </div>
            </a>

            <a :href="'https://wa.me/964' + phoneNumbers[0].slice(1)" target="_blank"
                class="bg-white hover:bg-yellow-dark transition-all duration-150  w-full rounded-full py-1 sm:py-2 flex items-center px-5">
                <div class="w-2/6">واتساب</div>
                <div class="w-2/6"></div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/whatsappOutlineDark.svg" alt="whatsapp icon">
                </div>
            </a>
        </div>
    </div>
</template>

<script >
export default {
    data() {
        return {
            phoneNumbers: ["07701234567", "07701234567"],
        }
    },
    methods: {
        copyText(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
        }
    },
}
</script>
