import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useAuthStore } from "@/stores";


const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});
function isAuthenticated() {
  const store = useAuthStore();
  return store.isAuth();
}

router.beforeEach((to, from, next) => {
  const publicRoutes = ['Login', 'signup', 'reset'];
  const authorizedRoutes = ['myShop', 'AddProduct', 'AddService'];

  if (publicRoutes.includes(to.name) && isAuthenticated()) {
    next({ name: 'myShop' });
  } else if (authorizedRoutes.includes(to.name) && !isAuthenticated()) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;