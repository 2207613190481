import { defineStore } from 'pinia'
import api from '@/api';
import router from '@/router';
export const useProductStore = defineStore({
    id: 'product',
    state: () => ({
        id: '',
        name: '',
        details: '',
        price: '',
        currency: {},
        currencyList: [{ id: 'IQD', name: 'د.ع' }, { id: 'USD', name: '$' }, { id: '' , name: 'الغاء'}],
        active: '',
        mainCategory: {},
        subCategory: {},
        images: [],
        removedImages: [],
        shop: {
            id: '',
            name: '',
            phoneNumber: '',
            government: '',
            area: '',
            nearestPoint: '',
            details: '',
            facebook: '',
            instagram: '',
            whatsapp: '',
            image: '',
        },

        error: null,
        loading: false,
    }),

    actions: {
        async fetchProduct(id) {

            this.loading = true;
            this.error = null;
            this.clearForm();
            try {
                const res = await api.getProductById(id);
                const product = res.data;
                const shop = res.data.user;

                //product
                this.id = product.id;
                this.name = product.name;
                this.details = product.details;
                this.price = product.price;
                this.currency.id = product.currency;
                this.active = product.active;
                this.mainCategory = { id: product.prod_main_cat?.id || '', name: product.prod_main_cat?.name || '' };
                this.subCategory = { id: product.prod_sub_cat?.id || '', name: product.prod_sub_cat?.name || '' }
                this.images = product.prod_image?.map((image) => ({ src: image.url, file: "", id: image.id })) || [];

                //shop
                this.shop.id = shop.id;
                this.shop.name = shop.name;
                this.shop.phoneNumber = shop.phone;
                this.shop.government = shop.province?.name;
                this.shop.area = shop.neighborhood?.name;
                this.shop.nearestPoint = shop.landmark;
                this.shop.details = shop.details;
                this.shop.facebook = shop.facebook;
                this.shop.instagram = shop.instagram;
                this.shop.whatsapp = shop.whatsapp;
                this.shop.image = shop.user_image[0]?.url || '';
                this.error = null;
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAuthProduct(id) {
            this.loading = true;
            this.error = null;
            this.clearForm();
            try {
                const res = await api.getAuthProductById(id);
                const product = res.data;
                const shop = res.data.user;

                //product
                this.id = product.id;
                this.name = product.name;
                this.details = product.details;
                this.price = product.price;
                this.currency.id = product.currency;
                this.active = product.active;
                this.mainCategory = { id: product.prod_main_cat?.id || '', name: product.prod_main_cat?.name || '' };
                this.subCategory = { id: product.prod_sub_cat?.id || '', name: product.prod_sub_cat?.name || '' }
                this.images = product.prod_image?.map((image) => ({ src: image.url, file: "", id: image.id })) || [];

                //shop
                this.shop.id = shop.id;
                this.shop.name = shop.name;
                this.shop.phoneNumber = shop.phone;
                this.shop.government = shop.province?.name;
                this.shop.area = shop.neighborhood?.name;
                this.shop.nearestPoint = shop.landmark;
                this.shop.details = shop.details;
                this.shop.facebook = shop.facebook;
                this.shop.instagram = shop.instagram;
                this.shop.whatsapp = shop.whatsapp;
                this.shop.image = shop.user_image[0]?.url || '';
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        addImage(image) {
            this.images.push(image)
        },
        removeImage(index) {
            if (this.images[index]?.id) {
                this.removedImages.push(this.images[index]?.id);
            }
            this.images.splice(index, 1);
        },
        clearForm() {
            this.shop = {};
            this.name = '';
            this.mainCategory = {};
            this.subCategory = {};
            this.details = '';
            this.currency = {};
            this.price = '';
            this.images = []
        },
        currencyNameFromCurrencyId() {
            this.currency = this.currencyList.find(currency => currency.id === this.currency.id);
        },
        async addProduct() {
            this.loading = true;
            const images = this.images.map(image => image.file);
            try {
                await api.addProduct(
                    this.name,
                    this.mainCategory.id,
                    this.subCategory.id,
                    this.details,
                    this.currency.id ?? '',
                    this.price,
                    images || []
                );
                this.clearForm()
                this.error = null;
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async updateProduct() {
            this.loading = true;
            const images = this.images
                .filter(image => image.file && image.file !== "")
                .map(image => image.file);
            try {
                await api.updateProduct(
                    this.id,
                    this.name,
                    this.mainCategory.id,
                    this.subCategory.id,
                    this.details,
                    this.currency.id ?? '',
                    this.price,
                    images || [],
                    this.removedImages
                );
                this.error = null;
                router.go(-1);
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async activeProduct(productId) {
            this.loading = true;
            try {
                await api.activeProduct(productId);
                this.error = null;
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async deactiveProduct(productId) {
            this.loading = true;
            try {
                await api.deactiveProduct(productId);
                this.error = null;
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async deleteProduct(productId) {
            this.loading = true;
            try {
                await api.deleteProduct(productId);
                this.error = null;
            } catch (err) {

                this.error = err;
            } finally {
                this.loading = false;
            }
        },

    },
})
