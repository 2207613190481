import { defineStore } from 'pinia';
import api from '@/api';

export const useProvincesStore = defineStore({
    id: 'provinces',

    state: () => ({
        provinces: [],
        areas: [],
        error: null,
        loading: false,
    }),
    actions: {
        async fetchAllProvinces() {
            this.loading = true;
            try {
                const res = await api.getProvinces();
                this.provinces = res.data;
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchUsedProvinces() {
            this.loading = true;
            try {
                const res = await api.getUsedProvinces();
                this.provinces = res.data;
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAreasByProvinceId(provinceId) {
            const province = this.provinces.find(p => p.id === provinceId);
            this.areas =  province ? province.neighborhood : [];
        },
    }
});
