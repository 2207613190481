<template>
    <div v-if="(loading || items.length)" >
        <PrimaryDivider  title="مميزة">
            <template v-slot:icon>
                <img src="@/assets/icons/star.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
            </template>
        </PrimaryDivider>
        <div class="min-h-[20vh] pt-5 pb-10">
            <StarSliders v-if="!loading && !error && items.length" :items="items" />
            <div class="w-full flex justify-center">
                <EmptyResponse v-if="!loading && !error && !items.length" />
                <LoadingCircle v-if="loading && !error" class=" h-10 " />
                <ErrorResponse v-if="error" />
            </div>
        </div>
    </div>
</template>

<script>
import StarSliders from '@/components/sliders/StarSliders.vue';
import { useItemsStore, useFilterStore } from '@/stores'
import { onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import PrimaryDivider from '@/components/dividers/PrimaryDivider.vue';

export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        onMounted(async () => {
            await itemsStore.fetchFavItems();
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchFavItems();
                }
            }
        );
        return {
            items: storeToRefs(itemsStore).favItems,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
        };
    },

    components: { StarSliders, LoadingCircle, ErrorResponse, EmptyResponse, PrimaryDivider },

}
</script>

