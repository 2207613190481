<template>
    <StanderButton v-if="!condition || condition()" :class="`${bgColor} ${textColor} ${bgHoverColor}`" :onclick="onclick"
        :imageSrc="icon">
        {{ label }}
    </StanderButton>
</template>
  
<script>
import StanderButton from './StanderButton.vue';

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        condition: {
            type: Function,
            required: false,
        },
        bgColor: {
            type: String,
            required: false,
            default: 'bg-gray-300',
        },
        bgHoverColor: {
            type: String,
            required: false,
            default: '',
        },
        textColor: {
            type: String,
            required: false,
            default: 'text-gray-900',
        },
        onclick: {
            type: Function,
            required: false,
            default: () => { },
        },
    },
    components: {
        StanderButton,
    },
};
</script>
  