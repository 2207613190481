import { defineStore } from 'pinia';
import api from '@/api';
import { useFilterStore } from "./index"

export const useItemsStore = defineStore({
    id: 'items',

    state: () => ({
        products: [],
        services: [],
        shops: [],
        favItems: [],
        error: null,
        loading: false,
        filter: useFilterStore(),
        pagination: {
            currentPage: 0,
            firstPageUrl: '',
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            lastPage: 0,
            perPage: 0,
            total: 0,
        }
    }),
    actions: {
        async fetchFavItems() {
            this.favItems = [];
            this.loading = true;
            try {
                const productResponse = await api.getFavProductsItems(this.filter.province);
                const serviceResponse = await api.getFavServicesItems(this.filter.province);
                const favProducts = productResponse.data;
                const favServices = serviceResponse.data;
                this.favItems = favProducts.concat(favServices);
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAllProducts(page = 1) {
            this.loading = true;
            this.products = [];
            try {
                const res = await api.getAllProducts(
                    this.filter.province,
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page
                );
                this.products = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAllServices(page = 1) {
            this.loading = true;
            this.services = [];

            try {
                const res = await api.getAllServices(
                    this.filter.province,
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page,
                );
                this.services = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

        async fetchAllShops(page = 1) {
            this.loading = true;
            this.shops = []
            try {
                const res = await api.getAllShops(
                    this.filter.province,
                    this.filter.searchText,
                    page
                );
                this.shops = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchSampleShops() {
            this.loading = true;
            this.shops = []
            try {
                const res = await api.getSampleShops(this.filter.province);
                this.shops = res.data;
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async search() {
            if (this.filter.generalCategory === 'المواد') {
                await this.fetchAllProducts()
            } else if (this.filter.generalCategory === 'الخدمات') {
                await this.fetchAllServices()
            } else if (this.filter.generalCategory === 'المعارض') {
                await this.fetchAllShops()
            }
        },

        async searchForSpecificUser(userId) {
            if (this.filter.generalCategory === 'المواد') {
                await this.fetchUserProducts(1, userId)
            } else if (this.filter.generalCategory === 'الخدمات') {
                await this.fetchUserServices(1, userId)
            }
        },
        async searchForAuthUser() {
            if (this.filter.generalCategory === 'المواد') {
                await this.fetchAuthUserProducts(1)
            } else if (this.filter.generalCategory === 'الخدمات') {
                await this.fetchAuthUserServices(1)
            }
        },


        async fetchUserProducts(page = 1, UserId) {
            this.loading = true;
            this.products = [];
            try {
                const res = await api.getUserProducts(
                    UserId,
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page
                );
                this.products = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAuthUserProducts(page = 1) {
            this.loading = true;
            this.products = [];
            try {
                const res = await api.getAuthUserProducts(
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page
                );
                this.products = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

        async fetchUserServices(page = 1, UserId) {
            this.loading = true;
            this.services = [];

            try {
                const res = await api.getUserServices(
                    UserId,
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page,
                );
                this.services = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchAuthUserServices(page = 1) {
            this.loading = true;
            this.services = [];

            try {
                const res = await api.getAuthUserServices(
                    this.filter.mainCategory,
                    this.filter.subCategory,
                    this.filter.searchText,
                    page,
                );
                this.services = res.data.data;
                this.pagination = {
                    currentPage: res.data.current_page,
                    firstPageUrl: res.data.first_page_url,
                    lastPageUrl: res.data.last_page_url,
                    nextPageUrl: res.data.next_page_url,
                    prevPageUrl: res.data.prev_page_url,
                    lastPage: res.data.last_page,
                    perPage: res.data.per_page,
                    total: res.data.total,
                }
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

    },

});



