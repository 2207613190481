<template>
    <ServiceProductContainer v-if="!loading && !error">
        <HorizontalItemCard v-for="item in items" :route="'/service/' + item.id" :key="item.id"
            :imageSrc="item.first_srv_image?.url || emptyImage" :shopName="item.user.name" :itemName="item.name"
            :shopLocation="item.user.landmark" :price="item.price"  :currency="item.currency === 'IQD'?'د.ع':'$' " />
    </ServiceProductContainer>
    <div class="w-full flex justify-center">
        <EmptyResponse v-if="!loading && !error && !items.length" />
        <LoadingCircle v-if="loading && !error" class=" h-10 " />
        <ErrorResponse v-if="error" />
    </div>
    <div class="mt-20">
        <PaginationItems v-if="(items.length > 0 && !error) || loading" :currentPage="pagination.currentPage"
            :totalPages="pagination.lastPage" :pagesToShow="pagination.lastPage" @update:current-page="fetchSpecificPage" />
    </div>
</template>

<script >
import ServiceProductContainer from '@/components/containers/ServiceProductContainer.vue';
import HorizontalItemCard from '@/components/cards/HorizontalItemCard.vue';
import PaginationItems from '@/components/PaginationItems.vue';
import { onMounted, watch } from 'vue';
import { useFilterStore, useItemsStore } from '@/stores'
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import emptyImage from '@/assets/icons/emptyImage.png';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import LoadingCircle from '@/components/response/LoadingCircle.vue';


export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        const route = useRoute();

        onMounted(async () => {
            await itemsStore.fetchUserServices(1, route.params.id);
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchUserServices(1, route.params.id);
                }
            }
        );
        const fetchSpecificPage = async (page) => {
            await itemsStore.fetchUserServices(page, route.params.id);
        }

        return {
            fetchSpecificPage,
            items: storeToRefs(itemsStore).services,
            pagination: storeToRefs(itemsStore).pagination,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
            itemsFlg: storeToRefs(itemsStore).itemsFlg,
        };
    },
    data() {
        return {
            emptyImage
        };
    },
    components: { ServiceProductContainer, HorizontalItemCard, PaginationItems, ErrorResponse, EmptyResponse, LoadingCircle }
}
</script>