
<template>
    <div class="flex flex-col gap-3">
        <div class="flex justify-between items-center">
            <div class=" flex gap-1">
                <StanderButton class="w-32 "
                    :class="generalCategory === 'المواد' ? 'bg-yellow-primary hover:bg-yellow-dark ' : 'bg-gray-300 hover:bg-gray-600 hover:text-gray-100'"
                    :onclick="() => { setGeneralCategory('المواد') }">
                    المواد
                </StanderButton>
                <StanderButton class="w-32   "
                    :class="generalCategory === 'الخدمات' ? 'bg-yellow-primary hover:bg-yellow-dark ' : 'bg-gray-300 hover:bg-gray-600 hover:text-gray-100'"
                    :onclick="() => { setGeneralCategory('الخدمات') }">
                    الخدمات
                </StanderButton>
            </div>
        </div>
        <div id="searchBox" class="flex gap-5 justify-between w-full">
            <div class="flex  gap-5 justify-between  w-4/5">
                <TextField id="search" placeholder="اكتب للبحث" v-model="searchText" extendClasses="w-3/4" :onEnter="search" />
                <StanderButton class="w-1/4 bg-yellow-primary hover:bg-yellow-dark "
                    :class="searchText === '' ? 'opacity-70 hover:bg-yellow-primary' : ''" :disabled="searchText === ''"
                    :onclick="search">
                    بحث
                </StanderButton>   
            </div>

            <StanderButton class="w-1/5 bg-gray-900 text-gray-100 hover:bg-black " :onclick="clearSearch">
                الغاء البحث
            </StanderButton>
        </div>
    
        <div class="flex w-full justify-between items-center  gap-3">
            <div class="flex gap-3  items-center w-4/5 ">
                <div id="mainCategories">
                    <DropDown @onSelect="getSubCategories" :listItems="categories" v-model="selectedMainCategories"
                        defaultText="عرض كل القوائم الرئيسية" />
                </div>
                <div id="subCategories">
                    <DropDown :listItems="subCategories" v-model="selectedSubCategories"
                        defaultText="عرض كل القوائم الفرعية" />
                </div>
                <StanderButton class="w-32 bg-yellow-primary hover:bg-yellow-dark "
                    :class="selectedMainCategories === '' ? 'opacity-70 hover:bg-yellow-primary' : ''"
                    :disabled="selectedMainCategories === ''" :onclick="search">
                    تطبيق
                </StanderButton>
            </div>

            <StanderButton class="w-1/5  bg-gray-300 hover:bg-gray-600  hover:text-gray-100 " :onclick="clearFilters">
                اظهار الكل
            </StanderButton>

        </div>
    </div>
</template>

<script>
import StanderButton from '@/components/buttons/StanderButton.vue';
import TextField from '@/components/inputComponent/TextField.vue';
import DropDown from '@/components/DropDown.vue';
import { useFilterStore, useItemsStore, useCategoriesStore } from '@/stores';
import { onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

export default {
    setup() {
        const filterStore = useFilterStore()
        const itemsStore = useItemsStore();
        const categoriesStore = useCategoriesStore();
        const route = useRoute();


        onMounted(async () => {
            filterStore.generalCategory = 'المواد'
            await categoriesStore.fetchAllCategories();
        });
        const search = async () => {
            await itemsStore.searchForSpecificUser(route.params.id)
        }
        const clearFilters = async () => {
            if (filterStore.searchText !== "" || filterStore.SelectedProvince !== "" || filterStore.mainCategory !== "" || filterStore.subCategory !== "") {
                filterStore.searchText = "";
                filterStore.SelectedProvince = "";
                filterStore.mainCategory = "";
                filterStore.subCategory = "";
                await search();
            }
        }
        const clearSearch = async () => {
            filterStore.searchText = "";
            await search()
        }
        watch(
            () => filterStore.generalCategory,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await categoriesStore.fetchAllCategories();
                }
            }
        );


        return {
            clearFilters,
            clearSearch,
            searchText: storeToRefs(filterStore).searchText,
            setGeneralCategory: filterStore.setGeneralCategory,
            generalCategory: storeToRefs(filterStore).generalCategory,
            search: search,
            selectedMainCategories: storeToRefs(filterStore).mainCategory,
            selectedSubCategories: storeToRefs(filterStore).subCategory,
            categories: storeToRefs(categoriesStore).categories,
            subCategories: storeToRefs(categoriesStore).subCategories,
            getSubCategories: categoriesStore.getSubCategoriesOfSelectedMainCategories,
        }
    },
    components: {
        TextField,
        StanderButton,
        DropDown,
    },

}
</script>
