<template>
    <div class=" flex flex-col gap-5   ">
        <TextField id="shopName" :maxLength="40" label="اسم المعرض" disabled="true" placeholder="اسم المعرض" v-model="authStore.name" />
        <TextField id="phone" label="هاتف" :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
            placeholder="هاتف" disabled="true" v-model="authStore.phoneNumber" />
        <div class="flex gap-5 pt-10 ">
            <TextField id="phoneVerification" :validate="validateOtp" :validationErrors="errorMessage.otp"
                placeholder="اكتب رمز التاكيد" v-model="authStore.otbCode" />
            <StanderButton :onClick="handleSendOtp" :disabled="isSendingOtp" class=" w-1/3  self-center bg-gray-900"
                :class="isSendingOtp ? (' text-gray-600') : ('  hover:bg-black text-gray-100')">
                اعادة ارسال
            </StanderButton>
        </div>
        <div>
            <p class="text-xs text-gray-600 text-center">سيتم ارسال رمز التاكيد الى رقم الهاتف المسجل</p>
        </div>
        <div>
            <p class="text-xs text-red-danger text-center"> {{ authStore.error}} </p>
        </div>
        <div class="flex gap-2 w-full pt-10 ">
            <StanderButton :onClick="nextStep" class=" w-2/3  self-center bg-yellow-primary hover:bg-yellow-dark ">
                تسجيل
            </StanderButton>
            <LoadingCircle v-if="authStore.loading && !authStore.error" class="h-8" />
            <StanderButton :onClick="previousStep" class=" w-1/3  self-center bg-gray-900 hover:bg-black text-gray-100">
                السابق
            </StanderButton>
        </div>

            <RouterLink to="/myShop" class="text-center" >
                <button class="text-center underline hover:underline-offset-2 transition-all duration-300 ease-in-out' "
                    :onclick="authStore.clearShopDetails"> الغاء</button>
            </RouterLink>

    </div>
</template>
  
<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { phoneNumberRule, requiredRule } from '../../../assets/validation'
import StanderButton from "@/components/buttons/StanderButton.vue";
import { onMounted } from "vue";
import LoadingCircle from "@/components/response/LoadingCircle.vue";
export default {
    components: { TextField, StanderButton, LoadingCircle },
    setup() {
        const authStore = useAuthStore();
        onMounted(async () => {
            await authStore.sendOtp();
        });
        return { authStore }
    },

    data() {
        return {
            errorMessage: {
                name: [],
                phoneNumber: [],
                otp: []
            },
            isValid: {
                name: false,
                phoneNumber: false,
                otp: false
            },
            isSendingOtp: false,
        }
    },

    methods: {
        nextStep() {
            this.validatePhoneNumber(this.authStore.phoneNumber);
            this.validateName(this.authStore.name);
            this.validateOtp(this.authStore.otbCode);
            (this.isValid.name &&
                this.isValid.phoneNumber && this.isValid.otp) ?
                this.update() : ""
        },
        previousStep() {
            this.$emit('changeStep', 2);
        },
        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
        validateName(value) {
            this.errorMessage.name = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.name.push(requiredErrorMessage)
            this.errorMessage.name.length ? this.isValid.name = false : this.isValid.name = true
        },
        validateOtp(value) {
            this.errorMessage.otp = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.otp.push(requiredErrorMessage)
            this.errorMessage.otp.length ? this.isValid.otp = false : this.isValid.otp = true
        },
        async handleSendOtp() {
            if (!this.isSendingOtp) {
                this.isSendingOtp = true;
                this.authStore.sendOtp();
                setTimeout(() => {
                    this.isSendingOtp = false;
                }, 30000);
            }
        },
        async update() {
            await this.authStore.updateShopWithChangedPhoneNumber()
        },

    },
};
</script>