<template>
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center z-50   ">
    <div class="bg-white pb-4 rounded-xl shadow-lg z-30">
      <h2 class="mb-4 w-full rounded-t-xl bg-gray-900 font-bold text-xl text-gray-100 p-4 ">{{ title }}</h2>
      <div class="pt-8 px-8 " > 
        <p class="mb-8">{{ message }}</p>
        <div class="flex justify-center gap-5">
          <StanderButton @click="selectOption(true)" class=" px-8 py-2   text-white hover:bg-red-danger-dark bg-red-danger">
            {{trueText}}
          </StanderButton>
          <StanderButton @click="selectOption(false)"
            class=" px-8 py-2 bg-yellow-primary text-gray-900 hover:bg-yellow-dark ">
            {{falseText}}
          </StanderButton>
        </div>
      </div>
    </div>
    <div class="absolute inset-0 bg-black opacity-30 z-10 "></div>
  </div>
</template>

<script>
import StanderButton from './buttons/StanderButton.vue';

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        message: {
            type: String,
            default: "",
        },
        trueText: {
            type: String,
            default: "",
        },
        falseText: {
            type: String,
            default: "",
        },
    },
    methods: {
        selectOption(option) {
            this.$emit("optionSelected", option);
        },
    },
    components: { StanderButton }
};
</script>


