// src/api/index.js
import adApi from './adApi';
import itemApi from './itemApi';
import provincesApi from './provincesApi'
import categoriesApi from './categoriesApi';
import shopApi from './shopApi';
import productApi from './productApi';
import serviceApi from './serviceApi';
import authApi from './authApi';
import notificationApi from './notificationApi';

const api = {
    ...adApi,
    ...itemApi,
    ...provincesApi,
    ...categoriesApi,
    ...shopApi,
    ...productApi,
    ...serviceApi,
    ...authApi,
    ...notificationApi,
};

export default api;
