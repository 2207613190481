import { defineStore } from 'pinia';
import api from '@/api';
import { useFilterStore } from "./index"

export const useCategoriesStore = defineStore({
    id: 'categories',

    state: () => ({
        categories: [],
        serviceCategories: [],
        productCategories: [],
        subCategories: [],
        error: null,
        loading: false,
        filter: useFilterStore(),
    }),
    actions: {
        async fetchAllCategories() {
            this.error = null;
            this.loading = true;
            this.categories = [];
            this.subCategories = [];
            if (this.filter.generalCategory === 'المواد') {
                await this.fetchProductsCategories();
            } else if (this.filter.generalCategory === 'الخدمات') {
                await this.fetchServicesCategories();
            }
        },
        async fetchServicesCategories() {
            this.error = null;
            this.loading = true;
            this.categories = [];
            this.subCategories = [];
            try {
                const res = await api.getServicesMainCategories();
                this.categories = res.data;
                this.serviceCategories = res.data;
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async fetchProductsCategories() {
            this.error = null;
            this.loading = true;
            try {
                const res = await api.getProductsMainCategories();
                this.categories = res.data;
                this.productCategories = res.data;
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        getSubCategoriesOfSelectedMainCategories() {
            this.error = null;
            this.loading = true;
            this.subCategories = [];
            this.filter.subCategory = "";
            if (this.filter.mainCategory !== "") {
                const mainCategory = this.categories.filter((item) => item.id === this.filter.mainCategory);
                this.subCategories = this.filter.generalCategory === 'المواد' ? mainCategory[0].prod_sub_cat : mainCategory[0].srv_sub_cat;
            } else {
                this.subCategories = []
                this.filter.subCategory = ""
            }
        },
        getSubCategMainCategWithoutFilter(mainCategoryId, generalCategory) {
            this.error = null;
            this.loading = true;
            if (mainCategoryId !== "") {
                const mainCategory = this.categories.filter((item) => item.id === mainCategoryId);
                this.subCategories = generalCategory === 'المواد' ? mainCategory[0].prod_sub_cat : mainCategory[0].srv_sub_cat;
            } else {
                this.subCategories = []
            }
        }
    }



});
