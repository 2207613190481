<template>
    <PrimaryDivider title="المواد"         
    titleUrl="/items" @click="()=>{setGeneralCategory('المواد')}"
 >
        <template v-slot:icon>
            <img src="@/assets/icons/service.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
        </template>
    </PrimaryDivider>
    <div class="min-h-[20vh]">
        <CategoriesContainer v-if="!loading && !error">
            <CategoryCard v-for="category in categories" :key="category.id" :id="category.id"
                :imageSrc="category.image_url || emptyImage" @showItems="mainCategoryProducts"
                :buttomText="category.name" />
        </CategoriesContainer>
        <div class="w-full flex justify-center">
            <EmptyResponse v-if="!loading && !error && !categories.length" />
            <LoadingCircle v-if="loading && !error" class=" h-10 " />
            <ErrorResponse v-if="error" />
        </div>
    </div>
</template>

<script >
import CategoriesContainer from '@/components/containers/CategoriesContainer.vue';
import CategoryCard from '@/components/cards/CategoryCard.vue';
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import emptyImage from '@/assets/icons/emptyImage.png'
import { useCategoriesStore, useFilterStore } from '@/stores'
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import PrimaryDivider from '@/components/dividers/PrimaryDivider.vue';

export default {
    components: { CategoriesContainer, CategoryCard, LoadingCircle, ErrorResponse, EmptyResponse, PrimaryDivider },
    setup() {
        const categoriesStore = useCategoriesStore();
        const filterStore = useFilterStore()
        onMounted(async () => {
            await categoriesStore.fetchProductsCategories()
        });
        return {
            mainCategoryProducts: filterStore.mainCategoryProducts,
            categories: storeToRefs(categoriesStore).productCategories,
            loading: storeToRefs(categoriesStore).loading,
            error: storeToRefs(categoriesStore).error,
            setGeneralCategory: filterStore.setGeneralCategory
        };
    },
    data() {
        return {
            emptyImage
        }
    }
}
</script>
