import axios from './axios';

const authApi = {
    async login(phoneNumber, password) {
        const data = { phone: phoneNumber, password: password }
        return await axios.post('/login', data);
    },
    async logout() {
        return await axios.get('/logout');
    },
    async getAuthShop() {
        return await axios.get('/user');
    },
    async signup(phone,
        verificationCode,
        password,
        name,
        provinceId,
        neighborhoodId,
        landmark,
        details,
        image,
        facebook,
        instagram,
        whatsapp) {

        const data = new FormData();
        data.append('phone', phone);
        data.append('verification_code', verificationCode);
        data.append('password', password);
        data.append('name', name);
        data.append('province_id', provinceId);
        data.append('neighborhood_id', neighborhoodId);
        data.append('landmark', landmark);
        data.append('details', details);
        image === undefined ? "" : data.append('image', image) ;
        data.append('facebook', facebook);
        data.append('instagram', instagram);
        data.append('whatsapp', whatsapp);
        return await axios.post('/user/create_me', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async updateShopWithChangedPhoneNumber(
        phone,
        verificationCode,
        name,
        provinceId,
        neighborhoodId,
        landmark,
        details,
        image,
        facebook,
        instagram,
        whatsapp,
        remove) {

        const data = new FormData();
        data.append('phone', phone);
        data.append('verification_code', verificationCode);
        data.append('name', name);
        data.append('province_id', provinceId);
        data.append('neighborhood_id', neighborhoodId);
        data.append('landmark', landmark);
        data.append('details', details);
        image === undefined ? "" : data.append('image', image) ;
        data.append('facebook', facebook);
        data.append('instagram', instagram);
        data.append('whatsapp', whatsapp);
        remove ? data.append('remove', parseInt(remove)) : "";
        return await axios.post('/user/update_me2', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async updateShopWithoutChangedPhoneNumber(
        phone,
        name,
        provinceId,
        neighborhoodId,
        landmark,
        details,
        image,
        facebook,
        instagram,
        whatsapp,
        remove) {
        const data = new FormData();
        data.append('phone', phone);
        data.append('name', name);
        data.append('province_id', provinceId);
        data.append('neighborhood_id', neighborhoodId);
        data.append('landmark', landmark);
        data.append('details', details);
        image ? data.append('image', image) : "";
        data.append('facebook', facebook);
        data.append('instagram', instagram);
        data.append('whatsapp', whatsapp);
        remove ? data.append('remove', parseInt(remove)) : "";
        return await axios.post('/user/update_me1', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async requestOtp(phone) {
        const data = { phone: phone }
        return await axios.post('/user/create_otp', data);
    },
    async resetPasswordRequestOtp(phone) {
        const data = { phone: phone }
        return await axios.post('/password/forget_otp', data);
    },
    async checkOtp(phone, verificationCode) {
        const data = { phone: phone, verification_code: verificationCode }
        return await axios.post('/password/forget1', data);
    },
    async setNewPassword(newPassword) {
        const data = { new_password: newPassword }
        return await axios.post('/password/forget2', data);
    }
}

export default authApi;

