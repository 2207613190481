<template>
    <div class="flex justify-between h-[calc(100vh_-_4rem)]   w-screen  ">
        <div class="w-full  bg-gray-100  md:w-4/6 h-full flex flex-col justify-start items-center ">
            <div class="basis-1/4 flex justify-center  items-center ">
                <h1 class="font-bold text-3xl text-yellow-primary mt-10   ">تسجيل الدخول</h1>
            </div>
            <div class="w-4/6  flex flex-col gap-4  basis-1/4 justify-center items-center  ">
                <TextField id="phone" :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
                    placeholder="هاتف" v-model="authStore.phoneNumber" />
                <TextField id="password" :validate="validatePassword" :validationErrors="errorMessage.password"
                    placeholder="رمز المرور" v-model="authStore.password" />
                <CustomErrorResponse v-if="authStore.error">
                    خطا في رقم الهاتف او كلمة المرور
                </CustomErrorResponse>
                <div class=" flex items-center md:gap-10 gap-2 mt-10 ">
                    <h3 class="text-sm">نسيت رمز المرور؟</h3>
                    <RouterLink to="/reset" class=" overflow-hidden " >
                        <StanderButton class="bg-gray-300 hover:bg-gray-600 hover:text-gray-100 px-8 md:px-16  ">
                            تغيير رمز المرور
                        </StanderButton>
                    </RouterLink>
                </div>
            </div>

            <div class="basis-1/4 flex justify-center  items-center">
                <StanderButton :onclick="submit" class="bg-yellow-primary hover:bg-yellow-dark  px-28">
                    تاكيد
                </StanderButton>
                <LoadingCircle v-if="authStore.loading && !authStore.error" class="h-8" />
            </div>

            <div class=" flex items-center md:gap-10 gap-3 ">
                <h3 class="text-sm">ليس لديك حساب مسبقاً؟</h3>
                <router-link to="/signup">
                    <StanderButton class="bg-gray-900 hover:bg-black text-gray-100 px-10 md:px-16">انشاء حساب
                    </StanderButton>
                </router-link>
            </div>
        </div>
        <div class="bg-gray-100 h-full  hidden  w-2/6  md:relative  md:flex  md:items-center  ">
            <img src="@/assets/icons/bg-1.jpg" class="  md:absolute  md:left-0  h-full object-cover object-left" alt="background">
        </div>
    </div>
</template>

<script>
import { phoneNumberRule, requiredRule } from '@/assets/validation';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import StanderButton from '@/components/buttons/StanderButton.vue';
import TextField from '@/components/inputComponent/TextField.vue';
import CustomErrorResponse from '@/components/response/CustomErrorResponse.vue';

import { useAuthStore } from '@/stores';

export default {

    setup() {
        const authStore = useAuthStore();
        return { authStore }
    },
    data() {
        return {
            errorMessage: {
                phoneNumber: [],
                password: []
            },
            isValid: {
                phoneNumber: false,
                password: false,
            },
        }
    },
    methods: {
        submit() {
            this.validatePhoneNumber(this.authStore.phoneNumber);
            this.validatePassword(this.authStore.password);
            (this.isValid.password &&
                this.isValid.phoneNumber) ?
                this.login() : ""
        },

        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
        validatePassword(value) {
            this.errorMessage.password = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.password.push(requiredErrorMessage)
            this.errorMessage.password.length ? this.isValid.password = false : this.isValid.password = true
        },
        async login() {
            await this.authStore.login()
        }

    },
    components: { TextField, StanderButton, LoadingCircle, CustomErrorResponse }




}
</script>