<template>
    <div>
        <RouterLink v-if="!isAuth" to="/signup">
            <button
                class="w-40 bg-yellow-primary text-gray-900 rounded-full flex justify-between font-bold pl-1 pr-5 py-1 items-center">
                سجل معرضك
                <img class="h-8" :src="backfillShopIcon" alt="logo" />
            </button>
        </RouterLink>
        <RouterLink v-if="isAuth" to="/myShop">
            <button class="w-10 h-10 bg-yellow-primary text-gray-900 rounded-full p-1">
                <img :src="backfillShopIcon" alt="logo" />
            </button>
        </RouterLink>
    </div>
</template>
  
<script>
import { useRouter } from 'vue-router';
import backfillShopIcon from '@/assets/icons/backfillShopIcon.svg';

export default {
    props: {
        isAuth: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const router = useRouter();

        return {
            backfillShopIcon,
            router,
        };
    },
};
</script>
  