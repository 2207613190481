import axios from './axios';

const itemApi = {
    async getFavProductsItems(province = "") {
        const data = { province_id: province }
        return await axios.post('/product/getAllFav', data);
    },
    async getFavServicesItems(province = "") {
        const data = { province_id: province }
        return await axios.post('/service/getAllFav', data);
    },
    async getAllProducts(province = "", productMainCategory = "", productSubCategory = "", productName = "", page = 1) {
        const data = { province_id: province, prod_main_cat_id: productMainCategory, prod_sub_cat_id: productSubCategory, prod_name: productName }
        return await axios.post('/product/getAll?page=' + page, data);
    },
    async getAllServices(province = "", ServiceMainCategory = "", serviceSubCategory = "", serviceName = "", page = 1) {
        const data = { province_id: province, srv_main_cat_id: ServiceMainCategory, srv_sub_cat_id: serviceSubCategory, srv_name: serviceName }
        return await axios.post('/service/getAll?page=' + page, data);

    },
    async getAllShops(province = "", shopName = "", page = 1) {
        const data = { province_id: province, user_name: shopName }
        return await axios.post('/user/getAll?page=' + page, data);
    },
};


export default itemApi;
