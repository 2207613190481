import axios from './axios';

const provinceApi = {
    async getProvinces() {
        return await axios.get('/province/getAll');
    },
    async getUsedProvinces() {
        return await axios.get('/province/used/getAll');
    },
};

export default provinceApi;
