<template>
    <PrimaryDivider v-if="generalCategory === 'المعارض'" title="المعارض">
        <template v-slot:icon>
            <img src="@/assets/icons/shop.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
        </template>
    </PrimaryDivider>
    <PrimaryDivider v-if="generalCategory === 'المواد'" title="المواد">
        <template v-slot:icon>
            <img src="@/assets/icons/item.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
        </template>
    </PrimaryDivider>
    <PrimaryDivider v-if="generalCategory === 'الخدمات'" title="الخدمات">
        <template v-slot:icon>
            <img src="@/assets/icons/service.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
        </template>
    </PrimaryDivider>
</template>

<script>
import PrimaryDivider from '@/components/dividers/PrimaryDivider.vue';

export default {
    props: {
        generalCategory: {
            type: String,
            require: true,
        }
    },
    components: {
        PrimaryDivider,
    }
}
</script>