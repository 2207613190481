<template>
    <div class="flex justify-center  md:justify-between h-[calc(100vh_-_4rem)] w-full">
        <div class="w-4/6 flex justify-center items-center h-full ">
            <div class="w-full md:w-1/2">
                <component :is="currentStepComponent" @change-step="changeStep" />
            </div>
        </div>
        <div class="g-gray-100  hidden  h-full  w-2/6  md:relative  md:flex  md:items-center ">
            <img src="@/assets/icons/bg-1.jpg" class="md:absolute  md:left-0  h-full object-cover object-left "
                alt="background">
        </div>
    </div>
</template>


<script>
import ShopDetails from './steps/ShopDetails.vue';
import PhoneVerification from './steps/PhoneVerification.vue';
import SocialAccounts from './steps/SocialAccounts.vue';
import { useAuthStore } from '@/stores';
import { onMounted } from 'vue';

export default {
    name: 'LoginForm',
    setup() {
        const authStore = useAuthStore();
        onMounted(async () => {
            authStore.clearShopDetails();
            await authStore.getShop();
        });
    },
    components: {
        ShopDetails,
        PhoneVerification,
        SocialAccounts,
    },
    data() {
        return {
            currentStep: 1,
        };
    },

    computed: {
        currentStepComponent() {
            switch (this.currentStep) {
                case 1:
                    return ShopDetails;
                case 2:
                    return SocialAccounts;
                case 3:
                    return PhoneVerification;
                default:
                    return ShopDetails;
            }

        },
    },
    methods: {
        changeStep(step) {
            this.currentStep = step;
        },
    },

}
</script>



