<template>
  <footer class="bg-gray-300 text-gray-600 py-6 w-screen mt-10" style="direction: ltr;">
    <div class="container mx-auto px-4">
      <div class="sm:flex sm:justify-between sm:items-center mb-4">
        <div class="text-center mb-6 sm:mb-4 sm:text-left">
          <p><a href="#" class="text-sm  hover:text-gray-900">Terms and conditions</a></p>
          <p><a href="#" class="text-sm  hover:text-gray-900">About us</a></p>
          <p><a href="#" class="text-sm  hover:text-gray-900">Privacy policy</a></p>
          <p><a href="#" class="text-sm  hover:text-gray-900">SUPPORT</a></p>
          <p><a href="#" class="text-sm  hover:text-gray-900">FAQ</a></p>
        </div>

        <div class="text-center font-bold ">
          <p class="mb-2">SOCIAL MEDIA</p>
          <div class="flex justify-center">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" class=" hover:text-gray-300 mx-2">
             <img src="@/assets/icons/instagram.svg " class="h-12 w-auto" alt="instagram icon">
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"
              class=" hover:text-gray-300 mx-2">
              <img src="@/assets/icons/facebook.svg " class="h-12 w-auto" alt="facebook icon">
            </a>
          </div>
        </div>
      </div>

      <div class="border-t border-[#C1C1C1] py-4 flex  justify-between items-center">
        
        <div class="text-left">
          <p class="text-sm">Copyright © 2010-2023 Binna Mall Company All rights reserved.</p>
        </div>

        <div class="text-right">
          <img src="@/assets/icons/grayLogo.svg " alt="Company Logo" class="h-6 w-auto">
        </div>
      </div>
    </div>
  </footer>
</template>

