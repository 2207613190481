<template>
    <div class="w-full flex justify-center">
        <LoadingCircle v-if="loading && !error" class=" h-10 " />
        <ErrorResponse v-if="error" />
    </div>
    <div v-if="!loading && !error" class="w-full flex flex-col lg:flex-row gap-4 items-center">
        <div class="rounded-lg flex flex-col gap-2 w-full lg:w-1/2 justify-around">
            <div class="rounded-lg w-full overflow-hidden mb-3 flex justify-center">
                <img v-if="images[0]" class="aspect-[21/9] object-contain bg-gray-100 overflow-hidden max-w-full h-auto"
                    alt="shop Image" :src="images[0].src">
            </div>
            <div class="flex w-full text-xs sm:text-base">
                <div class="w-2/6 text-yellow-primary">اسم المعرض</div>
                <div class="w-4/6 text-gray-900 font-bold">{{ name }}</div>
            </div>
            <div class="flex w-full text-xs sm:text-base">
                <div class="w-2/6 text-yellow-primary justify-between">العنوان</div>
                <div class="text-gray-900 font-bold break-words">{{ government.name + '/' + area.name + '/' + nearestPoint
                }}
                </div>
            </div>
            <div v-if="details" class="flex w-full text-xs sm:text-base">
                <div class="w-2/6 text-yellow-primary justify-between">تفاصيل المعرض</div>
                <div class="text-gray-900 font-bold break-words text-justify w-full ">{{ details }}</div>
            </div>
            <!-- Add 'py-1 sm:py-2' for responsive padding -->
            <div v-if="phoneNumber" @click="() => { copyText(phoneNumber) }"
                class="bg-yellow-primary w-full rounded-full py-1 sm:py-2 flex items-center px-5 cursor-pointer ">
                <div class="w-1/6 md:w-2/6">الهاتف</div>
                <div class="w-3/6 md:2/6">{{ phoneNumber }}</div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    <span v-if="!phoneNumberCopied">نسخ الرقم</span>
                    <span v-else class="text-gray-900">تم النسخ!</span>
                    <img src="@/assets/icons/phone.svg" alt="phone Icon">
                </div>

            </div>
            <a v-if="facebook" :href="'https://' + facebook" target="_blank"
                class="bg-[#2383E3] text-white w-full rounded-full py-1 sm:py-2 flex items-center px-5">
                <div class="w-2/6"> حساب الفيسبوك</div>
                <div class="w-2/6"></div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/facebookOutline.svg" alt="facebook icon">
                </div>
            </a>
            <a v-if="instagram" :href="'https://' + instagram" target="_blank"
                class="bg-[#9F35AD] text-white w-full rounded-full p-2 flex items-center px-5 ">
                <div class="w-2/6  ">حساب الانستكرام</div>
                <div class="w-2/6"></div>
                <div class=" w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/instaOutline.svg" alt="insta icon">
                </div>
            </a>

            <a v-if="whatsapp" :href="'https://wa.me/964' + whatsapp.slice(1)" target="_blank"
                class="bg-[#43BE1F] text-white w-full rounded-full py-1 sm:py-2 flex items-center px-5">
                <div class="w-2/6">واتساب</div>
                <div class="w-2/6"></div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/whatsapp.svg" alt="whatsapp icon">
                </div>
            </a>
        </div>
        <div class="rounded-lg w-full md:w-1/2 flex flex-col lg:items-end gap-3 justify-center items-center">
            <RouterLink to="/myShop/update" class="w-2/3">
                <StanderButton class="w-full text-white bg-gray-900 hover:bg-black">
                    تعديل بيانات المعرض
                </StanderButton>
            </RouterLink>
            <RouterLink to="/product/add" class="w-2/3 ">
                <StanderButton class="w-full text-white bg-gray-900 hover:bg-black">
                    اضافة مادة
                </StanderButton>
            </RouterLink>
            <RouterLink to="/service/add" class="w-2/3 ">
                <StanderButton class="w-full text-white bg-gray-900 hover:bg-black">
                    اضافة خدمة
                </StanderButton>
            </RouterLink>
            <StanderButton class="w-2/3  text-white bg-red-danger hover:bg-red-danger-dark" :onclick="logout">
                تسجيل خروج
            </StanderButton>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import StanderButton from '@/components/buttons/StanderButton.vue';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';

export default {
    setup() {
        const authStore = useAuthStore();
        onMounted(async () => {
            await authStore.getShop();
        });
        return {
            logout: authStore.logout,
            name: storeToRefs(authStore).name,
            phoneNumber: storeToRefs(authStore).phoneNumber,
            government: storeToRefs(authStore).government,
            area: storeToRefs(authStore).area,
            nearestPoint: storeToRefs(authStore).nearestPoint,
            details: storeToRefs(authStore).details,
            facebook: storeToRefs(authStore).facebook,
            instagram: storeToRefs(authStore).instagram,
            whatsapp: storeToRefs(authStore).whatsapp,
            images: storeToRefs(authStore).images,
            error: storeToRefs(authStore).error,
            loading: storeToRefs(authStore).loading,
        };
    },
    data() {
        return {
            phoneNumberCopied: false,
        };
    },
    methods: {
        copyText(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.phoneNumberCopied = true;
                    setTimeout(() => {
                        this.phoneNumberCopied = false;
                    }, 2000);
                })
        }
    },
    components: { StanderButton, LoadingCircle, ErrorResponse }
};
</script>