<template>
    <div class="w-full">
        <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700">
            {{ label }}
        </label>
        <div class="relative">
            <input type="number" :id="id" autocomplete="off" :disabled="disabled" valid="false" :value="modelValue"
                @input="onInput" @blur="checkValidate" :placeholder="placeholder" :class="[
                    extendClasses,
                    'form-input block w-full py-2 px-3 border border-gray-600 bg-white text-gray-900 rounded-full shadow-sm focus:outline-none     focus:border-gray-900 sm:text-sm',
                    !validationErrors.length || ('border-red-danger focus:border-red-danger')]" />

            <div v-if="maxLengthIntegerPart && maxLengthDecimalPart"
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400 text-sm">
                {{ calculateRemainingCharacters() }} / {{ maxLengthIntegerPart +"."+ maxLengthDecimalPart }}
            </div>
        </div>
        <p v-for="validationError in validationErrors" :key="validationError" class="mx-3 mt-1  text-sm text-red-danger">
            *{{ validationError }}
        </p>
    </div>
</template>
  
<script>

export default {
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        maxLengthIntegerPart: {
            type: [Number, String],
            required: true,
            default: null,
        },
        maxLengthDecimalPart: {
            type: [Number, String],
            required: true,
            default: null,
        },
        extendClasses: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        validate: {
            type: Function,
            required: false,
            default: () => { },
        },
        validationErrors: {
            type: Array,
            required: false,
            default: new Array(),
        }
    },

    methods: {
        onInput(event) {
            const { maxLengthIntegerPart, maxLengthDecimalPart } = this;
            const { value } = event.target;
            const [integerPart, decimalPart] = value.split(".");
            let truncatedValue = '';
            if (integerPart) {
                truncatedValue += integerPart.slice(0, maxLengthIntegerPart);
            }
            if (decimalPart) {
                truncatedValue += '.' + decimalPart.slice(0, maxLengthDecimalPart);
            }
            if (truncatedValue !== value) {
                event.target.value = truncatedValue;
            }
            this.$emit("update:modelValue", event.target.value);
        },
        checkValidate(event) {
            this.validate(event.target.value);
        },
        calculateRemainingCharacters() {
            const { maxLengthIntegerPart, maxLengthDecimalPart, modelValue } = this;
            const [integerPart, decimalPart] = modelValue.split(".");
            const remainingIntegerPart = maxLengthIntegerPart - (integerPart ? integerPart.length : 0);
            const remainingDecimalPart = maxLengthDecimalPart - (decimalPart ? decimalPart.length : 0);
            return remainingIntegerPart + "." + remainingDecimalPart ;
        },
    },
  
};
</script>


