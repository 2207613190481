<template>
  <div id="app" class="flex flex-col min-h-screen  justify-start w-screen overflow-x-hidden ">
    <div >
      <NavBar />
    </div>
    <div  v-if="!isHiddenNavRoute"  class="mt-16"  >
      <AddsHeader  />
    </div> 
    <div class="w-full mt-16" >
      <router-view />
    </div>
    <PrimaryFooter  v-if="!isHiddenNavRoute"  />
  </div>
</template>

<script>
import { RouterView } from 'vue-router'
import PrimaryFooter from './components/footer/PrimaryFooter.vue';
import NavBar from './components/Nav/NavBar.vue';
import AddsHeader from './components/sections/AddsHeader.vue';
import { useRoute } from 'vue-router';
import {computed} from 'vue'
export default { 
  setup() {
    const route = useRoute();
    const hiddenRoutes = ['Login', 'signup' , 'reset', 'AddProduct' , 'AddService' , 'service' , 'product' ,'contact' , 'update'];
    const isHiddenNavRoute = computed(() => hiddenRoutes.includes(route.name));

    return {
      isHiddenNavRoute
    };
  },
  name: 'App',
  components: {
    RouterView,
    NavBar,
    AddsHeader,
    PrimaryFooter
  }
}
</script>


