<template>
    <div class="flex  justify-center h-[calc(100vh_-_4rem)] overflow-hidden">
        <div class="  flex flex-col justify-start items-center w-full   bg-gray-100  md:w-4/6 h-screen">
            <div class=" basis-1/4 w-full flex justify-center  items-center ">
                <h1 class="font-bold text-3xl text-yellow-primary mt-10   ">تغيير رمز المرور</h1>
            </div>
            <div class="w-4/6  basis-2/4 self-center flex justify-center items-center ">
                <div class=" w-full  ">
                    <component :is="currentStepComponent" @change-step="changeStep" />
                </div>
            </div>
        </div>

        <div class="bg-gray-300 hidden md:block   md:w-2/6 ">
            <img src="@/assets/icons/bg-1.jpg" class="object-cover h-full " alt="background">
        </div>

    </div>
</template>

<script>
import PhoneNumber from './steps/PhoneNumber.vue';
import PhoneVerification from './steps/PhoneVerification.vue';
import SetPassword from './steps/SetPassword.vue';

export default {
    name: 'LoginForm',
    components: {
        PhoneNumber,
        PhoneVerification,
        SetPassword,
    },
    data() {
        return {
            currentStep: 1,
        };
    },

    computed: {
        currentStepComponent() {
            switch (this.currentStep) {
                case 1:
                    return PhoneNumber;
                case 2:
                    return PhoneVerification;
                case 3:
                    return SetPassword;
                default:
                    return PhoneNumber;
            }

        },
    },
    methods: {
        changeStep(step) {
            this.currentStep = step;
        },
    },

}
</script>



