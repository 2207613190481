<template>
    <div class="flex flex-col  rounded-3xl shadow-lg overflow-hidden h-full w-full ">
        <img class="aspect-[1/1] object-contain overflow-hidden  w-full h-full " alt="item image" :src="imageSrc">
        <div class="flex-auto bg-gray-900 text-gray-300     flex flex-col justify-start items-start text-center px-3 py-2 ">
            <h1 class="md:text-base xl:text-xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl font-bold  text-right ">{{ 
            truncatedButtomText
            }}</h1>
            <p class="text-base lg:text-lg xl:text-sm  2xl:text-base 3xl:text-xl 4xl:text-3xl    text-justify  ">{{
                truncatedDescription 
                }}</p>
        </div>
    </div> 
</template>
 
<script >

export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
            default: "",
        },
        buttomText: {
            type: String,
            required: true,
            default: "",
        },
        description: {
            type: String,
            required: true,
            default: "",
        }
    },

  computed: {
    truncatedDescription() {
      return this.truncateText(this.description , 10);
    },
    truncatedButtomText() {
      return this.truncateText(this.buttomText, 3);
    }
  },
  methods: {
    truncateText(text, maxWords ) {
      const words = text.split(" ");
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(" ") + "...";
      } else {
        return text;
      }
    }
  }
}
</script>
