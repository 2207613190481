<template>
    <div class="flex flex-col gap-5 justify-center  ">
        <div>
            <div class="image-uploader w-full flex flex-col justify-center items-center ">
                <label
                    class=" w-full mt-3 bg-gray-900 hover:bg-black text-gray-100 cursor-pointer flex justify-center items-center rounded-xl ">
                    <span class="text-base  font-bold  p-3 ">صورة المعرض</span>
                    <input type="file" accept="image/*" @change="handleFiles" class="hidden " />
                </label>
                <p class=" text-gray-400 text-sm pointer-events-none w-full text-center  " >يفضل ان تكون ابعاد الصورة 21/9 مثل 1024:432</p>
                <div v-if="authStore.images[0]" class="flex  flex-col w-full justify-center items-center mt-5 ">
                    <div class=" aspect-[21/9] bg-gray-300 rounded-lg overflow-hidden  object-contain flex justify-center items-center ">
                        <img :src="authStore.images[0].src" class=" aspect-[21/9] object-contain" >
                    </div>
                    <StanderButton @click="removeImage"
                        class=" w-full mt-3 text-gray-900 bg-yellow-primary hover:bg-yellow-dark ">
                        مسح الصورة
                    </StanderButton>
                </div>
            </div>
        </div>
        <div>
            <TextField id="shopName" :maxLength="40" :validate="validateName" :validationErrors="errorMessage.name" placeholder="اسم المعرض"
                v-model="authStore.name" />
        </div>
        <div>
            <TextField id="phone" :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
                placeholder="رقم الهاتف" v-model="authStore.phoneNumber" />
        </div>

        <div class="flex flex-col sm:flex-row gap-2 justify-center items-center ">
            <div class=" w-full sm:w-5/12 ">
                <DropDown :listItems="provinces" v-model="authStore.government" defaultText="اختر المحافظة"
                    :validate="validateGovernment" :validationErrors="errorMessage.government"
                    extendClasses=" first:text-[#a9a9a9] border border-gray-600 bg-white text-gray-900  shadow-sm focus:outline-none focus:border-gray-900  "
                    :objectValueProperties="['id', 'name']" />
            </div>
            <div class="w-full sm:w-7/12">
                <DropDown :listItems="areas" v-model="authStore.area"
                    extendClasses="first:text-[#a9a9a9] border border-gray-600 bg-white text-gray-900  shadow-sm focus:outline-none focus:border-gray-900  "
                    defaultText="اختر المنطقة" :validate="validateArea" :validationErrors="errorMessage.area"
                    :objectValueProperties="['id', 'name']" />
            </div>
        </div>
        <div>
            <TextField id="nearestPoint" :maxLength="30" v-model="authStore.nearestPoint" :rules="requiredRule" placeholder="اقرب نقطة دالة"
                :validate="validateNearestPoint" :validationErrors="errorMessage.nearestPoint" />
        </div>
        <div>
            <TextAreaField :maxLength="750" id="shopDetails" v-model="authStore.details" placeholder="التفاصيل" :validate="validateDetails"
                :validationErrors="errorMessage.details" />
        </div>
        <StanderButton :disabled="false" :onClick="nextStep"
            class=" w-1/2  self-center bg-yellow-primary hover:bg-yellow-dark ">
            التالي
        </StanderButton>
        <RouterLink to="/myShop" class="text-center" >
            <button class="text-center underline hover:underline-offset-2 transition-all duration-300 ease-in-out' "
                :onclick="authStore.clearShopDetails"> الغاء</button>
        </RouterLink>
    </div>
</template>

<script>
import { useAuthStore, useProvincesStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import TextAreaField from './../../../components/inputComponent/TextAreaField.vue'
import { phoneNumberRule, requiredRule } from '../../../assets/validation'
import { onMounted, watch } from "vue";
import { storeToRefs } from 'pinia';
import DropDown from "@/components/DropDown.vue";
import StanderButton from "@/components/buttons/StanderButton.vue";

export default {
    components: { TextField, TextAreaField, DropDown, StanderButton },
    setup() {
        const authStore = useAuthStore();
        const provincesStore = useProvincesStore();

        const handleFiles = (event) => {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            authStore.addImage({ src: url, file: file });
        };


        const removeImage = () => {
            authStore.removeImage();
        };
        onMounted(async () => {
            await provincesStore.fetchUsedProvinces();
        });
        watch(() => authStore.government.id, () => {
            provincesStore.fetchAreasByProvinceId(authStore.government.id);
        })
        return {
            handleFiles, removeImage,
            authStore,
            provinces: storeToRefs(provincesStore).provinces,
            areas: storeToRefs(provincesStore).areas
        };
    },
    data() {
        return {
            errorMessage: {
                name: [],
                phoneNumber: [],
                government: [],
                area: [],
                nearestPoint: [],
                details: []
            },
            isValid: {
                name: false,
                phoneNumber: false,
                government: false,
                area: false,
                nearestPoint: false,
                details: false
            }
        }
    },

    methods: {
        nextStep() {
            this.validateGovernment(this.authStore.government);
            this.validateName(this.authStore.name);
            this.validatePhoneNumber(this.authStore.phoneNumber);
            this.validateArea(this.authStore.area);
            this.validateNearestPoint(this.authStore.nearestPoint);
            this.validateDetails(this.authStore.details);
            (this.isValid.name &&
                this.isValid.phoneNumber &&
                this.isValid.government &&
                this.isValid.area &&
                this.isValid.nearestPoint &&
                this.isValid.details) ?
                this.$emit('changeStep', 2) : ""
        },
        validateName(value) {
            this.errorMessage.name = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.name.push(requiredErrorMessage)
            this.errorMessage.name.length ? this.isValid.name = false : this.isValid.name = true
        },
        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
        validateGovernment(value) {
            this.errorMessage.government = [];
            const requiredErrorMessage = requiredRule(value.id);
            requiredErrorMessage === null ? "" : this.errorMessage.government.push(requiredErrorMessage)
            this.errorMessage.government.length ? this.isValid.government = false : this.isValid.government = true
        },

        validateArea(value) {
            this.errorMessage.area = [];
            const requiredErrorMessage = requiredRule(value.id);
            requiredErrorMessage === null ? "" : this.errorMessage.area.push(requiredErrorMessage)
            this.errorMessage.area.length ? this.isValid.area = false : this.isValid.area = true
        },

        validateNearestPoint(value) {
            this.errorMessage.nearestPoint = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.nearestPoint.push(requiredErrorMessage)
            this.errorMessage.nearestPoint.length ? this.isValid.nearestPoint = false : this.isValid.nearestPoint = true
        },
        validateDetails(value) {
            this.errorMessage.details = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.details.push(requiredErrorMessage)
            this.errorMessage.details.length ? this.isValid.details = false : this.isValid.details = true
        },
    },
};
</script>