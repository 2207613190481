import axios from './axios';

const notificationApi = {

    async getAllNotifications() {
        return await axios.get('/notification/getAll');
    },
   
}
export default notificationApi;

