import axios from './axios';

const shopApi = {
    async getShop(userId) {
        const data = { user_id: userId }
        return await axios.post('/user/get', data);
    },
    async getSampleShops(provinceId) {
        const data = { province_id: provinceId }
        return await axios.post('/user/getSample', data);
    }
};


export default shopApi;
