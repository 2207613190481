<template>
    <Carousel :settings="settings" :breakpoints="breakpoints"  >
        <slide v-for="ad in ads" :key="ad">
            <div class="carousel__item"  >
                <AddsCard :imageUrl="ad.image_url" :adUrl="ad.ad_url" :buttonTitle="ad.button_title" />
            </div>
        </slide>
            <template #addons>
                <navigation />
                <pagination />
            </template>
    </carousel>
</template>
  
<script>

import './carousel.css'
import AddsCard from '../cards/AddsCard.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'App',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        AddsCard
    },
    props: {
        ads: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            settings: {
                itemsToShow: 2,
                wrapAround: true,
                snapAlign: "center",
                transition: "1500",
                pauseAutoplayOnHover:true,
                itemsToScroll: 1,
                dir:"rtl",
                autoplay:5000
            },
            breakpoints: {
                0: {
                    itemsToShow: 1,
                },
               
                640: {
                    itemsToShow:  2,
                }
            }
        }
    }


}
</script>

<style scoped>


.carousel__viewport {
    perspective: 2000px;
}

.carousel__track {
    transform-style: preserve-3d;
}

.carousel__slide--sliding {
    transition: 0.5s;
}



.carousel__slide--active~.carousel__slide {
    transform: rotateY(10deg) scale(0.8);
}

.carousel__slide--prev {
    opacity: 0.5;
    transform: rotateY(-10deg) scale(0.8);
}

.carousel__slide--next {
    opacity: 0.5;
    transform: rotateY(10deg) scale(0.8);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
}
</style>
  