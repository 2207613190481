<template>
    <div id="searchBox" class="flex flex-row gap-3 md:gap-5 justify-between w-full">
        <TextField id="search" placeholder="اكتب للبحث" v-model="searchText" extendClasses="w-3/4"
            :onEnter="navigateToItemsPage" />
        <StanderButton class="w-1/4 bg-yellow-primary hover:bg-yellow-dark text-gray-900 " :disabled="searchText === ''"
            :onclick="navigateToItemsPage" :class="searchButtonClass">
            بحث
        </StanderButton>

    </div>
</template>

<script>
import StanderButton from '@/components/buttons/StanderButton.vue';
import TextField from '@/components/inputComponent/TextField.vue';
import { useRouter } from 'vue-router';
import { useFilterStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export default {
    setup() {
        const filterStore = useFilterStore()
        const router = useRouter();
        const navigateToItemsPage = () => {
            filterStore.subCategory = "",
                filterStore.mainCategory = ""
            filterStore.generalCategory = 'المواد'
            router.push('/items');
        };

        const searchButtonClass = computed(() => {
            return `w-1/4 bg-yellow-primary hover:bg-yellow-dark ${filterStore.searchText === "" ? "opacity-70 hover:bg-yellow-primary" : ""
                }`;
        });

        return {
            navigateToItemsPage,
            searchText: storeToRefs(filterStore).searchText,
            searchButtonClass
        };

    },

    components: {
        TextField,
        StanderButton,
    },
    methods: {

    }

}
</script>