import { defineStore } from 'pinia';

export const useFilterStore = defineStore({
    id: 'Filters',

    state: () => ({
        searchText: "",
        generalCategory: "المواد",
        mainCategory: "",
        subCategory: "",
        province: "",
    }),

    actions: {
        setGeneralCategory(generalCategory) {
            this.generalCategory = generalCategory;
        },
        setMainCategory(mainCategory) {
            this.mainCategory = mainCategory;
        },
        mainCategoryServices(mainCategoryId){
            this.setGeneralCategory('الخدمات');
            this.setMainCategory(mainCategoryId);
        },
        mainCategoryProducts(mainCategoryId) {
            this.setGeneralCategory('المواد');
            this.setMainCategory(mainCategoryId);
        },
    }

});
