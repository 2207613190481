<template>
    <div class="w-full justify-center flex flex-col items-center my-10  min-h-[calc(100vh_-_4rem)]">
        <div class="flex w-3/4 md:w-3/4 gap-5 items-center flex-col md:flex-row ">
            <h1 class="font-bold text-2xl pb-10 md:pb-0  md:text-xl w-3/4 md:w-2/12 text-center ">تعديل مادة </h1>
            <div class=" w-full md:w-10/12 flex justify-center items-center">
                <div class="h-[0px] md:h-[2px] w-0  md:w-[40%] sm:hidden md:block bg-gray-300">
                </div>
                <StanderButton :onclick="save"
                    class=" w-[55%] md:w-[40%]  text-gray-900 bg-yellow-primary hover:bg-yellow-dark "
                    :disabled="loading">
                    تاكيد تعديل المنتج
                </StanderButton>
                <LoadingCircle v-if="loading && !error" class="h-8" />
                <RouterLink to="/myShop"
                    class=" rounded-3xl w-[35%] md:w-[20%] flex justify-center  mr-5 text-gray-100 bg-gray-900 hover:bg-black">
                    <StanderButton :onclick="clearForm">
                        الغاء
                    </StanderButton>
                </RouterLink>
            </div>
        </div>
        <ErrorResponse class="p-10" v-if="!loading && error?.message === 'Network Error'" />
        <CustomErrorResponse class="p-10" v-if="!loading && error && error?.message !== 'Network Error'">
            خطأ,تأكد من المعلومات المدخلة
        </CustomErrorResponse>

        <div class=" w-full md:w-3/4 flex flex-col-reverse  items-center   md:flex-row md:items-start gap-5 mt-10  ">
            <div class=" rounded-md  flex flex-col gap-2 w-3/4 md:w-1/2 justify-around ">
                <div class=" flex flex-col gap-4  border-b border-gray-900 pb-3">
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary   ">اسم المادة</div>
                        <div class="text-gray-900 font-bold w-full ">
                            <TextField id="ProductName" placeholder="اكتب الاسم" v-model="name" extendClasses="w-full"
                                :maxLength="30" />
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التصنيف الرئيسي</div>
                        <div class=" text-gray-900 font-bold  break-words w-full  ">
                            <DropDown @onSelect="getSubCategories" :listItems="categories"
                                v-model="selectedMainCategories.id" defaultText="عرض كل القوائم الرئيسية" />
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التصنيف الفرعي</div>
                        <div class=" text-gray-900 font-bold  w-full  ">
                            <DropDown :listItems="subCategories" v-model="selectedSubCategories.id"
                                defaultText="عرض كل القوائم الفرعية" />
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التفاصيل</div>
                        <div class="  text-gray-900 font-bold break-words w-full ">
                            <TextAreaField :maxLength="750" id="productDetails" placeholder="اكتب التفاصيل"
                                v-model="details" extendClasses="w-full" />
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">السعر</div>
                        <div class=" text-gray-900 font-bold  w-full   ">
                            <NumberField :maxLengthIntegerPart="9" :maxLengthDecimalPart="3" id="price"
                                placeholder="اكتب السعر" v-model="price" extendClasses="w-full" />
                        </div>
                        <DropDown :listItems="currencyList" v-model="currency.id" defaultText="العملة" />
                    </div>
                </div>
                <div class="rounded-md  flex justify-center  p-3 ">
                    <img v-if="ShopImage[0]" class=" aspect-[21/9] object-contain overflow-hidden " alt="shop Image"
                        :src="ShopImage[0].src">
                </div>
                <div class=" flex flex-col gap-4  ">
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary   ">اسم المعرض</div>
                        <div class="w-4/6 text-gray-900 font-bold ">{{ shopName }}</div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">العنوان</div>
                        <div class=" text-gray-900 font-bold  break-words  ">{{ government.name + '/' + area.name + '/' +
                            nearestPoint }}
                        </div>
                    </div>
                    <div v-if="shopDetails" class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between ">تفاصيل المعرض</div>
                        <div class="  text-gray-900 font-bold break-words ">{{ shopDetails }}</div>
                    </div>
                    <div v-if="phoneNumber" @click="() => { copyText(phoneNumber) }"
                        class="bg-yellow-primary w-full rounded-full p-2 flex items-center px-5 cursor-pointer ">
                        <div class="w-2/6  ">الهاتف</div>
                        <div class="w-2/6">{{ phoneNumber }}</div>
                        <div class="w-2/6 flex justify-end items-center gap-3">
                            <span v-if="!phoneNumberCopied">نسخ الرقم</span>
                            <span v-else class="text-gray-900">تم النسخ!</span>
                            <img src="@/assets/icons/phone.svg" alt="phone Icon">
                        </div>
                    </div>
                    <a v-if="facebook" :href="'https://' + facebook" target="_blank"
                        class="bg-[#2383E3] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  "> حساب الفيسبوك</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/facebookOutline.svg" alt="facebook icon">
                        </div>
                    </a>
                    <a v-if="instagram" :href="'https://' + instagram" target="_blank"
                        class="bg-[#9F35AD] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  ">حساب الانستكرام</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/instaOutline.svg" alt="insta icon">
                        </div>
                    </a>
                    <a v-if="whatsapp" :href="'https://wa.me/964' + whatsapp.slice(1)" target="_blank"
                        class="bg-[#43BE1F] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  ">واتساب</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp icon">
                        </div>
                    </a>
                </div>
            </div>
            <div class="w-3/4 md:w-1/2 h-full">
                <div class="c ">
                    <label
                        class=" w-full mt-3 bg-gray-900 hover:bg-black text-gray-100 cursor-pointer flex justify-center items-center rounded-xl ">
                        <span class="text-base  font-bold  p-3 ">اضف صور المنتج</span>
                        <input type="file" accept="image/*" multiple @change="handleFiles" class="hidden " />
                    </label>
                    <p class=" text-gray-400 text-sm pointer-events-none w-full text-center  ">يفضل ان تكون ابعاد الصورة 1:1
                        مثل 500*500</p>
                    <div class="grid grid-cols-2 gap-2 gap-y-8 mt-5 justify-center items-center ">
                        <div v-for="(image, index) in images" v-bind:key="JSON.stringify(image)">
                            <div class="flex  flex-col ">
                                <div
                                    class=" aspect-square bg-gray-300 rounded-md overflow-hidden flex justify-center items-center ">
                                    <img :src="image.src">
                                </div>
                                <StanderButton @click="() => removeImage(index)"
                                    class=" w-full mt-3 text-gray-900 bg-yellow-primary hover:bg-yellow-dark ">
                                    مسح الصورة
                                </StanderButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DilogTrueOrFalse :isOpen="isImageBig" title="بعض الصور كبيرة" trueText="متابعة" falseText="حذف الصور الكبيرة"
                :message="'الصور الكبيرة تؤثر على سرعة التحميل.'" @optionSelected="handleOptionSelectedInImageSizeDialog"
                @close="showDialog = false" />
        </div>
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { useProductStore, useCategoriesStore, useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia'
import DropDown from '@/components/DropDown.vue';
import TextField from '@/components/inputComponent/TextField.vue';
import TextAreaField from '@/components/inputComponent/TextAreaField.vue';
import NumberField from '@/components/inputComponent/NumberField.vue';
import StanderButton from '@/components/buttons/StanderButton.vue';
import { useRoute } from 'vue-router';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import CustomErrorResponse from '@/components/response/CustomErrorResponse.vue';
import DilogTrueOrFalse from '@/components/DilogTrueOrFalse.vue';

export default {
    setup() {
        const productStore = useProductStore();
        const authStore = useAuthStore();
        const categoriesStore = useCategoriesStore();
        const route = useRoute();
        const isImageBig = ref(false);
        const bigImageIndexes = ref([]);

        const getSubCategories = () => {
            categoriesStore.getSubCategMainCategWithoutFilter(productStore.mainCategory.id, 'المواد')
        }

        onMounted(async () => {
            await authStore.getShop();
            await productStore.fetchAuthProduct(route.params.id);
            await categoriesStore.fetchAllCategories();
            getSubCategories()
        })

        const handleFiles = (event) => {
            const files = event.target.files;
            bigImageIndexes.value = [];
            for (let file of files) {
                const url = URL.createObjectURL(file);
                productStore.addImage({ src: url, file: file });
                if (file.size > 1000000) {
                    bigImageIndexes.value.push(productStore.images.length - 1);
                }
                if (bigImageIndexes.value.length > 0) {
                    isImageBig.value = true;
                }
            }
        };
        const handleOptionSelectedInImageSizeDialog = (option) => {
            if (!option) {
                for (let i = bigImageIndexes.value.length - 1; i >= 0; i--) {
                    removeImage(bigImageIndexes.value[i]);
                }
                isImageBig.value = false;
            } else {
                isImageBig.value = false;
            }
        };
        const removeImage = (index) => {
            productStore.removeImage(index);
        };
        const clearForm = () => {
            productStore.clearForm();
        };

        const save = async () => {
            await productStore.updateProduct()
            this.clearForm()
        }
        return {
            getSubCategories, clearForm, save, handleFiles, removeImage,
            images: storeToRefs(productStore).images,
            details: storeToRefs(productStore).details,
            name: storeToRefs(productStore).name,
            price: storeToRefs(productStore).price,
            selectedMainCategories: storeToRefs(productStore).mainCategory,
            selectedSubCategories: storeToRefs(productStore).subCategory,
            currency: storeToRefs(productStore).currency,
            currencyList: productStore.currencyList,
            categories: storeToRefs(categoriesStore).categories,
            subCategories: storeToRefs(categoriesStore).subCategories,
            shopName: storeToRefs(authStore).name,
            phoneNumber: storeToRefs(authStore).phoneNumber,
            government: storeToRefs(authStore).government,
            area: storeToRefs(authStore).area,
            nearestPoint: storeToRefs(authStore).nearestPoint,
            shopDetails: storeToRefs(authStore).details,
            facebook: storeToRefs(authStore).facebook,
            instagram: storeToRefs(authStore).instagram,
            whatsapp: storeToRefs(authStore).whatsapp,
            ShopImage: storeToRefs(authStore).images,
            error: storeToRefs(productStore).error,
            loading: storeToRefs(productStore).loading,
            handleOptionSelectedInImageSizeDialog,
            isImageBig,
        };
    },
    data() {
        return {
            phoneNumberCopied: false,
        };
    },
    methods: {
        copyText(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.phoneNumberCopied = true;
                    setTimeout(() => {
                        this.phoneNumberCopied = false;
                    }, 2000);
                })
        }
    },
    components: { DropDown, TextField, TextAreaField, NumberField, StanderButton, LoadingCircle, ErrorResponse, CustomErrorResponse, DilogTrueOrFalse },
};
</script>