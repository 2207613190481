<template>
    <div class="flex flex-col gap-5 justify-center overflow-hidden  ">
        <h1 class="pr-3">
            قم بانشاء رمز المرور الخاص بك
        </h1>
        <TextField id="password" :validate="validatePassword" :validationErrors="errorMessage.password"
            placeholder="رمز المرور" v-model="password" />
        <TextField id="phone" :validate="validatePasswordCopy" :validationErrors="errorMessage.repeatPassword"
            placeholder="اعد كتابة رمز المرور" v-model="repeatPassword" />
            <CustomErrorResponse v-if="error" >
                <p>يبدو ان هنالك مشكلة  تأكد وحاول من جديد</p>
            </CustomErrorResponse>
        <div>
            <p class=" text-center text-gray-900 ">يفضل ان لا يقل رمز المرور عن ٨ احرف او ارقام</p>
            <p class=" text-center text-gray-900 ">ستحتاج رمز المرور عند تسجيل الدخول في المرة القادمة</p>
        </div>
        <div class="flex gap-2 w-full ">
            <StanderButton :disabled="false" :onClick="nextStep"
                class=" w-2/3 text-sm sm:text-base  self-center bg-yellow-primary hover:bg-yellow-dark ">
                تأكيد
            </StanderButton>
            <LoadingCircle v-if="loading && !error" class="h-8" />
            <StanderButton :disabled="false" :onClick="previousStep"
                class=" w-1/3 text-sm sm:text-base self-center bg-gray-900 hover:bg-black text-gray-100">
                السابق
            </StanderButton>
        </div>
    </div>
</template>
  
<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { isCopyRule, requiredRule } from '../../../assets/validation'
import StanderButton from "@/components/buttons/StanderButton.vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import LoadingCircle from "@/components/response/LoadingCircle.vue";
import CustomErrorResponse from "@/components/response/CustomErrorResponse.vue";
export default {
    components: { TextField, StanderButton, LoadingCircle, CustomErrorResponse },
    setup() {
        const authStore = useAuthStore();
        return {
            setNewPassword: authStore.setNewPassword,
            password:storeToRefs(authStore).password,
            repeatPassword:storeToRefs(authStore).repeatPassword,
            loading: storeToRefs(authStore).loading,
            error: storeToRefs(authStore).error,
        }
    },
    data() {
        return {
            errorMessage: {
                password: [],
                repeatPassword: [],
            },
            isValid: {
                password: false,
                repeatPassword: false,
            }
        }
    },

    methods: {
        async nextStep() {
            this.validatePassword(this.password);
            this.validatePasswordCopy(this.repeatPassword);
            if (this.isValid.password && this.isValid.repeatPassword) {
                await this.setNewPassword()
                this.error ? "" : router.push('/myShop')
            }
        },
        previousStep() {
            this.$emit('changeStep', 2);
        },
        validatePassword(value) {
            this.errorMessage.password = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.password.push(requiredErrorMessage)
            this.errorMessage.password.length ? this.isValid.password = false : this.isValid.password = true
        },
        validatePasswordCopy(value) {
            this.errorMessage.repeatPassword = [];
            const requiredErrorMessage = requiredRule(value);
            const isCopyErrorMessage = isCopyRule(value, this.password);
            requiredErrorMessage === null ? "" : this.errorMessage.repeatPassword.push(requiredErrorMessage)
            isCopyErrorMessage === null ? "" : this.errorMessage.repeatPassword.push(isCopyErrorMessage)
            this.errorMessage.repeatPassword.length ? this.isValid.repeatPassword = false : this.isValid.repeatPassword = true
        },


    },
};
</script>