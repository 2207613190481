<template>
    <div class="flex flex-col md:flex-row rounded-3xl overflow-hidden shadow-lg  items-center bg-gray-100">
        <img class=" bg-white aspect-[1/1] w-full md:w-2/6   overflow-hidden  object-contain" :alt="imageAlt" :src="imageSrc">
        <div class=" flex flex-col md:items-start  md:justify-start w-full md:w-4/6  p-3  gap-2 ">
            <div
                class=" flex items-center md:justify-start justify-center md:text-xl row-span-2 font-bold px-3 self-end text-gray-900 w-full ">
                <h1 class="w-3/6 md:w-2/6 ">الحالة </h1>
                    <label for="toggleB" class="flex items-center justify-center cursor-pointer" :onclick="() => { isActiveToggle(id) }">
                        <!-- toggle -->
                        <div class="relative">
                            <!-- input -->
                            <input type="checkbox" :checked="parseInt(isActive)" class="sr-only">
                            <!-- line -->
                            <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <!-- dot -->
                            <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                        </div>
                    </label>
            </div>
            <div
                class=" flex items-center md:justify-start justify-center md:text-xl row-span-2 font-bold px-3 self-end text-gray-900 w-full ">
                <h1 class="w-3/6 md:w-2/6 ">الاسم </h1>
                <span class="font-light">{{ itemName }}</span>
            </div>
            <div
                class=" flex items-center md:justify-start justify-center md:text-xl row-span-2 font-bold px-3 self-end text-gray-900 w-full ">
                <h1 class="w-3/6 md:w-2/6 ">التصنيف الرئيسي </h1>
                <span class="font-light">{{ mainCategory }}</span>
            </div>
            <div
                class=" flex items-center md:justify-start justify-center  md:text-xl row-span-2 font-bold px-3 self-end text-gray-900 w-full ">
                <h1 class="w-3/6 md:w-2/6">التصنيف الفرعي </h1>
                <span class="font-light">{{ subCategory }}</span>
            </div>
            <div v-if="price && currency"
                class=" flex items-center md:justify-start justify-center md:text-xl row-span-2 font-bold px-3 self-end text-gray-900 w-full ">
                <h1 class=" w-3/6 md:w-2/6 ">السعر </h1>
                <span class="font-light">{{ price + ' ' +  currency }}</span>
            </div>
            <div class="flex justify-center md:justify-start w-full gap-3">
                <RouterLink :to="editPageRoute"  >
                    <StanderButton class=" px-10 text-white bg-gray-900 hover:bg-black ">
                        تعديل
                    </StanderButton>
                </RouterLink>
                <StanderButton class="px-10 text-white  bg-red-danger hover:bg-red-danger-dark "
                    :onclick="() => { deleteItem(id , itemName) }">
                    حذف
                </StanderButton>
            </div>
        </div>
    </div>
</template>
 
<script >
import { RouterLink } from 'vue-router';
import StanderButton from '../buttons/StanderButton.vue';


export default {
    props: {
        id: {
            type: [ Number, String ],
            required: true,
            default: "",
        },
        imageSrc: {
            type: String,
            required: true,
            default: "",
        },
        imageAlt: {
            type: String,
            required: false,
            default: "",
        },
        shopName: {
            type: String,
            required: true,
            default: "",
        },
        itemName: {
            type: String,
            required: true,
            default: "",
        },
        mainCategory: {
            type: String,
            required: true,
            default: "",
        },
        subCategory: {
            type: String,
            required: true,
            default: "",
        },
        shopLocation: {
            type: String,
            required: true,
            default: "",
        },
        price: {
            type: String,
            required: true,
            default: "",
        },
        currency: {
            type: String,
            required: true,
            default: "",
        },
        
        isActive: {
            type: Number,
            required: true,
            default: 0
        },
        isActiveToggle: {
            type: Function,
            required: true,
            default: () => { }
        },
        deleteItem: {
            type: Function,
            required: true,
            default: () => { }
        },
        editPageRoute: {
            type: String,
            required: true,
            default: "",
        }
    },
    components: { StanderButton, RouterLink },


}
</script>

<style>
/* Toggle A */
input:checked~.dot {
    transform: translateX(100%);
    background-color: #48bb78;
}

/* Toggle B */
input:checked~.dot {
    transform: translateX(100%);
    background-color: #48bb78;
}
</style>
