import { defineStore } from 'pinia'
import api from '@/api';
import { useFilterStore } from './FilterStore';
export const useShopStore = defineStore({
  id: 'shop',
  state: () => ({
    id:'',
    name: '',
    phoneNumber: '',
    government: '',
    area: '',
    nearestPoint: '',
    details: '',
    isPhoneNumberVerified: '',
    password: '',
    repeatPassword: '',
    isPhoneNumberHasWhatsapp: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
    image:'',
    shopProducts: [],
    shopServices: [],
    error: null,
    loading: false,
    filter: useFilterStore(),
    pagination: {
      currentPage: 0,
      firstPageUrl: '',
      lastPageUrl: '',
      nextPageUrl: '',
      prevPageUrl: '',
      lastPage: 0,
      perPage: 0,
      total: 0,
    }
  }),

  actions: {
    async fetchShop(userId) {
      this.loading = true;
      try {
        const res = await api.getShop(userId);
        const shop = res.data;
        this.id = shop.id;
        this.name = shop.name;
        this.phoneNumber = shop.phone;
        this.government = shop.province.name;
        this.area = shop.neighborhood.name;
        this.nearestPoint = shop.landmark;
        this.details =  shop.details;
        this.facebook = shop.facebook;
        this.instagram = shop.instagram;
        this.whatsapp = shop.whatsapp;
        this.image = shop.user_image[0]?.url || '';
        this.error = null;
      } catch (err) {
        
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
})
