<template>
    <div class="w-full flex justify-center">
        <LoadingCircle v-if="loading && !error" class=" h-10 " />
        <ErrorResponse v-if="error" />
    </div>
    <div v-if="!loading && !error"
        class="w-full flex flex-col-reverse md:flex-row justify-center items-center  gap-4">
        <div class=" rounded-lg  flex flex-col  gap-2 w-full sm:w-5/6 md:w-1/2 justify-around ">
            <div class="flex  w-full  ">
                <div class="w-2/6 text-yellow-primary   ">اسم المعرض</div>
                <div class="w-4/6 text-gray-900 font-bold ">{{ name }}</div>
            </div>
            <div class="flex  w-full">
                <div class="w-2/6 text-yellow-primary justify-between">العنوان</div>
                <div class=" text-gray-900 font-bold  break-words  ">{{ government + '/' + area + '/' + nearestPoint }}
                </div>
            </div>
            <div class="flex  w-full">
                <div class="w-2/6 text-yellow-primary justify-between">تفاصيل المعرض</div>
                <div class="  text-gray-900 font-bold break-words  text-justify w-full  ">{{ details }}</div>
            </div>
            <div v-if="phoneNumber" @click="()=>{copyText(phoneNumber)}"
                class="bg-yellow-primary w-full rounded-full p-2 flex items-center px-5 cursor-pointer">
                <div class="w-1/6 md:w-2/6">الهاتف</div>
                <div class="w-3/6 md:2/6">{{ phoneNumber }}</div>
                <div class="w-2/6 flex justify-end items-center gap-3">
                    <span v-if="!phoneNumberCopied">نسخ الرقم</span>
                    <span v-else class="text-gray-900">تم النسخ!</span>
                    <img src="@/assets/icons/phone.svg" alt="phone Icon">
                </div>
            </div>
            <a v-if="facebook" :href="'https://' + facebook" target="_blank"
                class="bg-[#2383E3] text-white w-full rounded-full p-2 flex items-center px-5 cursor-pointer  ">
                <div class="w-2/6  "> حساب الفيسبوك</div>
                <div class="w-2/6"></div>
                <div class=" w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/facebookOutline.svg" alt="facebook icon">
                </div>
            </a>
            <a v-if="instagram" :href="'https://' + instagram" target="_blank"
                class="bg-[#9F35AD] text-white w-full rounded-full p-2 flex items-center px-5 cursor-pointer  ">
                <div class="w-2/6  ">حساب الانستكرام</div>
                <div class="w-2/6"></div>
                <div class=" w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/instaOutline.svg" alt="insta icon">
                </div>
            </a>
            <a v-if="whatsapp" :href="'https://wa.me/964' + whatsapp.slice(1)" target="_blank"
                class="bg-[#43BE1F] text-white w-full rounded-full p-2 flex items-center px-5 cursor-pointer  ">
                <div class="w-2/6  ">واتساب</div>
                <div class="w-2/6"></div>
                <div class=" w-2/6 flex justify-end items-center gap-3">
                    افتح
                    <img src="@/assets/icons/whatsapp.svg" alt="whatsapp icon">
                </div>
            </a>
        </div>
        <div class="w-full sm:5/6 md:w-1/2 flex justify-center  p-3 bg-gray-100 rounded-2xl ">
            <img class=" aspect-[21/9] object-contain overflow-hidden " alt="shop Image" :src="image">
        </div>
    </div>
</template>

<script  >
import { onMounted } from 'vue';
import { useShopStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';

export default {
    setup() {
        const shopStore = useShopStore();
        const route = useRoute();
        onMounted(async () => {
            await shopStore.fetchShop(route.params.id);
        });

        return {
            name: storeToRefs(shopStore).name,
            phoneNumber: storeToRefs(shopStore).phoneNumber,
            government: storeToRefs(shopStore).government,
            area: storeToRefs(shopStore).area,
            nearestPoint: storeToRefs(shopStore).nearestPoint,
            details: storeToRefs(shopStore).details,
            facebook: storeToRefs(shopStore).facebook,
            instagram: storeToRefs(shopStore).instagram,
            whatsapp: storeToRefs(shopStore).whatsapp,
            image: storeToRefs(shopStore).image,
            error: storeToRefs(shopStore).error,
            loading: storeToRefs(shopStore).loading,
        };
    },
    data() {
        return {
            phoneNumberCopied: false,
        };
    },
    methods: {
        copyText(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.phoneNumberCopied = true;
                    setTimeout(() => {
                        this.phoneNumberCopied = false;
                    }, 2000);
                })
        }
    },

    components: { LoadingCircle, ErrorResponse }
};
</script>