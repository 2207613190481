<template>
  <div class="flex flex-col gap-3">
    <div id="searchBox" class="flex flex-col md:flex-row gap-5 justify-between w-full">
      <div class="flex  gap-1 md:gap-5 justify-between w-full  md:w-4/5">
        <TextField id="search" placeholder="اكتب للبحث" v-model="searchText" extendClasses="w-3/4" :onEnter="search"  />
        <StanderButton class="w-1/4 bg-yellow-primary hover:bg-yellow-dark" :class="searchButtonClass"
          :disabled="searchText === ''" @click="search">
          بحث
        </StanderButton>
      </div>
      <StanderButton class="w-full md:w-1/5 bg-gray-900 text-gray-100 hover:bg-black" @click="clearSearch">
        الغاء البحث
      </StanderButton>
    </div>
    <div class="flex flex-col md:flex-row justify-between items-center gap-3">
      <div class=" w-full flex justify-center items-start md:justify-start gap-2 px-2">
        <StanderButton class="w-full md:w-2/12" v-for="category in generalCategories" :key="category"
          :class="getCategoryButtonClass(category)" @click="() => setGeneralCategory(category)">
          {{ category }}
        </StanderButton>
      </div>
      <div class="w-full md:w-3/12 rounded-full border border-gray-400 " id="province">
        <DropDown :listItems="Provinces" v-model="SelectedProvince" defaultText="اختر المحافظة" selectAllText="الكل" />
      </div>
    </div>
    <div v-if="generalCategory !== 'المعارض'" class="flex w-full flex-col md:flex-row justify-between items-center gap-3">
      <div class="flex gap-3 items-center w-full flex-col md:flex-row md:w-4/5">
        <div id="mainCategories" class="w-full border border-gray-400 rounded-full">
          <DropDown @onSelect="getSubCategories" :listItems="categories" v-model="selectedMainCategories"
            defaultText="عرض كل القوائم الرئيسية" selectAllText="الكل" />
        </div>
        <div id="subCategories" class="w-full border border-gray-400 rounded-full">
          <DropDown :listItems="subCategories" v-model="selectedSubCategories" defaultText="عرض كل القوائم الفرعية" selectAllText="الكل" />
        </div>
        <StanderButton class="w-full md:w-32" :class="applyButtonClass" :disabled="selectedMainCategories === ''"
          @click="search">
          تطبيق
        </StanderButton>
      </div>
      <StanderButton class="w-full md:w-1/5 bg-gray-300 hover:bg-gray-600 hover:text-gray-100" @click="clearFilters">
        اظهار الكل
      </StanderButton>
    </div>
  </div>
</template>
  
<script>
import StanderButton from '@/components/buttons/StanderButton.vue';
import TextField from '@/components/inputComponent/TextField.vue';
import DropDown from '@/components/DropDown.vue';
import { useFilterStore, useProvincesStore, useItemsStore, useCategoriesStore } from '@/stores';
import { onMounted, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';

export default {
  setup() {
    const filterStore = useFilterStore();
    const provincesStore = useProvincesStore();
    const itemsStore = useItemsStore();
    const categoriesStore = useCategoriesStore();

    onMounted(async () => {
      await provincesStore.fetchUsedProvinces();
      await categoriesStore.fetchAllCategories();
      categoriesStore.getSubCategoriesOfSelectedMainCategories()
    });

    const search = async () => {
      await itemsStore.search();
    };

    const clearFilters = async () => {
      if (
        filterStore.searchText !== "" ||
        filterStore.province !== "" ||
        filterStore.mainCategory !== "" ||
        filterStore.subCategory !== ""
      ) {
        filterStore.searchText = "";
        filterStore.province = "";
        filterStore.mainCategory = "";
        filterStore.subCategory = "";
        await search();
      }
    };

    const clearSearch = async () => {
      filterStore.searchText = "";
      await search();
    };

    watch(
      () => filterStore.generalCategory,
      async (newFilter, oldFilter) => {
        if (newFilter !== oldFilter) {
          await categoriesStore.fetchAllCategories();
          filterStore.mainCategory = '';
          filterStore.subCategory = '';
        }
      }
    );

    const getCategoryButtonClass = (category) =>
      `bg-${filterStore.generalCategory === category ? "yellow-primary" : "gray-300"
      } hover:bg-${filterStore.generalCategory === category ? "yellow-dark" : "gray-600"
      } hover:text-gray-100`;

    const searchButtonClass = computed(() => {
      return `w-1/4 bg-yellow-primary hover:bg-yellow-dark ${filterStore.searchText === "" ? "opacity-70 hover:bg-yellow-primary" : ""
        }`;
    });

    const applyButtonClass = computed(() => {
      return `w-32 bg-yellow-primary hover:bg-yellow-dark ${filterStore.mainCategory === "" ? "opacity-70 hover:bg-yellow-primary" : ""
        }`;
    });

    const generalCategories = ["المواد", "الخدمات", "المعارض"];

    return {
      clearFilters,
      clearSearch,
      searchText: storeToRefs(filterStore).searchText,
      SelectedProvince: storeToRefs(filterStore).province,
      Provinces: storeToRefs(provincesStore).provinces,
      setGeneralCategory: filterStore.setGeneralCategory,
      generalCategory: storeToRefs(filterStore).generalCategory,
      search,
      selectedMainCategories: storeToRefs(filterStore).mainCategory,
      selectedSubCategories: storeToRefs(filterStore).subCategory,
      categories: storeToRefs(categoriesStore).categories,
      subCategories: storeToRefs(categoriesStore).subCategories,
      getSubCategories: categoriesStore.getSubCategoriesOfSelectedMainCategories,
      getCategoryButtonClass,
      searchButtonClass,
      applyButtonClass,
      generalCategories,
    };
  },
  components: {
    TextField,
    StanderButton,
    DropDown,
  },
};
</script>
  
  
  
  