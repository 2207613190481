export const phoneNumberRule = (value) => {
    const pattern = /^07[3-9][0-9]{8}$/;

    if (!pattern.test(value)) {
        return "رقم الهاتف غير صالح. الرجاء إدخال رقم صحيح.";
    }

    return null;
};
export const validateInstagramUrl = (value) => {
    const pattern = /^(?:(?:www\.)?(?:instagram\.com|instagr\.am)\/[A-Za-z0-9_.]{1,30})?$/;

    if (!pattern.test(value)) {
        return "الرابط غير صالح. الرجاء إدخال رابط صحيح.";
    }
    return null;
}
export const validateFacebookUrl = (value) => {
    const pattern = /(?:www.)?(?:facebook\.com|fb\.me)\/(?:(?:\w)#!\/)?(?:pages\/)?(?:[\w-]\/)?(?:profile.php\?id=(?=\d.))?([\w-])?(?:[\w-.])?.*/g;
    if (!pattern.test(value)) {
        return "الرابط غير صالح. الرجاء إدخال رابط صحيح.";
    }
    return null;
}
export const requiredRule = (value) => value ? null : "مطلوب";
export const isCopyRule = (copyValue, originalValue) => copyValue === originalValue ? null : "يجب أن يكون هذا الحقل مطابقًا للحقل السابق";
