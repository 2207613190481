<template>
    <RouterLink to="/items" @click="onClick">
        <div class="flex flex-col rounded-3xl overflow-hidden shadow-lg">
            <img class="aspect-[1/1] object-contain overflow-hidden" :alt="imageAlt" :src="imageSrc">
            <div class="font-bold flex-auto bg-gray-900 text-gray-300">
                <h1 class="text-xs md:text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl w-full text-center">
                    {{ truncatedButtomText }}
                </h1>
            </div>
        </div>
    </RouterLink>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
            default: "",
        },
        imageAlt: {
            type: String,
            required: false,
            default: "",
        },
        buttomText: {
            type: String,
            required: true,
            default: "",
        },
        id: {
            type: [ Number, String],
            required: true,
            default: "",
        },
    },
    computed: {
        truncatedButtomText() {
            return this.truncateText(this.buttomText, 3);
        },
    },
    methods: {
        truncateText(text, maxWords) {
            const words = text.split(" ");
            if (words.length > maxWords) {
                return words.slice(0, maxWords).join(" ") + "...";
            } else {
                return text;
            }
        },
        onClick() {
            this.$emit("showItems", this.id);
        }
    },
    components: { RouterLink }
}

</script>