<template>
    <div class=" p-10 rounded-xl">
        <div v-if="!loading && !error" class="flex flex-col gap-2">
            <ItemCardAuth v-for="item in items" :key="item.id" :id="item.id"
                :imageSrc="item.first_srv_image?.url || emptyImage" :shopName="item.user.name" :itemName="item.name"
                :shopLocation="item.user.landmark" :price="item.price" :isActive="item.active" :deleteItem="handleItemToDelete"
                :isActiveToggle="toggleService" :editPageRoute="'/service/update/'+item.id" :currency="currencyNameByCode(item.currency)"
                :mainCategory="item.srv_main_cat?.name || ''" :subCategory="item.srv_sub_cat?.name || ''" />
        </div>
        <div class="w-full flex justify-center">
            <EmptyResponse v-if="!loading && !error && !items.length" />
            <LoadingCircle v-if="loading && !error" class=" h-10 " />
            <ErrorResponse v-if="error" />
        </div>
        <DilogTrueOrFalse :isOpen="showDialog" title="حذف" trueText="حذف" falseText="الغاء" :message=" 'هل انت متاكد بأنك تريد هذه الخدمة' +'  '  + serviceToDelete.name " @optionSelected="handleOptionSelected"
            @close="showDialog = false" />
        <div class="mt-20">
            <PaginationItems v-if="(items.length > 0 && !error) || loading" :currentPage="pagination.currentPage"
                :totalPages="pagination.lastPage" :pagesToShow="pagination.lastPage" @update:current-page="fetchSpecificPage" />
        </div>
    </div>
</template>

<script >
import PaginationItems from '@/components/PaginationItems.vue';
import { onMounted, watch } from 'vue';
import { useFilterStore, useItemsStore, useServiceStore } from '@/stores'
import { storeToRefs } from 'pinia';
import ItemCardAuth from '@/components/cards/ItemCardAuth.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import emptyImage from '@/assets/icons/emptyImage.png';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import {currencyNameByCode } from '@/assets/js/HelperFunction'
import DilogTrueOrFalse from '@/components/DilogTrueOrFalse.vue';

export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        const serviceStore = useServiceStore();

        onMounted(async () => {
            await itemsStore.fetchAuthUserServices(1);
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchAuthUserServices(1);
                }
            }
        );
        const fetchSpecificPage = async (page) => {
            await itemsStore.fetchAuthUserServices(page);
        }
        const toggleService = async (id) => {
            const item = itemsStore.services.find(item => item.id == id);
            if (!parseInt(item.active)) {
                await serviceStore.activeService(id)
            } else {
                await serviceStore.deactiveService(id)
            }
            serviceStore.error ? "" : item.active = parseInt(item.active) ^ 1
        }
        const deleteService = async (id) => {
            await serviceStore.deleteService(id)
            if (!(serviceStore.error)) {
                const index = itemsStore.services.findIndex(item => item.id == id);
                itemsStore.services.splice(index, 1);
            }
        }
        return {
            fetchSpecificPage,
            toggleService,
            deleteService,
            items: storeToRefs(itemsStore).services,
            pagination: storeToRefs(itemsStore).pagination,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
            itemsFlg: storeToRefs(itemsStore).itemsFlg,
        };
    },
    data() {
        return {
            emptyImage,
            showDialog: false,
            serviceToDelete:{},
        };
    },
    methods: {
        currencyNameByCode,
        handleOptionSelected(option) {
            if (option) {
                this.deleteService(this.serviceToDelete.id);
            }
            this.showDialog = false;
        },
        handleItemToDelete(id , name) {
            this.showDialog = true;
            this.serviceToDelete.id = id;
            this.serviceToDelete.name = name;
        }
    },
    components: { PaginationItems, ItemCardAuth, ErrorResponse, EmptyResponse, LoadingCircle, DilogTrueOrFalse }
}
</script>