<template>
    <Carousel :settings="settings" :breakpoints="breakpoints">
        <slide v-for="item in items" :key="item">
            <div class="carousel__item m-1 md:m-1 2xl:m-3 w-full h-full ">
                <RouterLink :to="item.service_id ? '/service/'+ item.service_id : '/product/'+ item.product_id ">
                    <VerticalItemsCard  v-if="item.product_id" :imageSrc="item.product.first_prod_image?.url || emptyImage"
                        :buttomText="item.product.name" :description="item.product.details" />    
                         <VerticalItemsCard  v-if="item.service_id" :imageSrc="item.service.srv?.url || emptyImage"
                        :buttomText="item.service.name" :description="item.service.details" />
                </RouterLink>
            </div>
        </slide>
        <template #addons>
            <navigation />
        </template>
    </carousel>
</template>
  
<script>
import './carousel.css'
import VerticalItemsCard from '../cards/VerticalItemsCard.vue';
import { Carousel, Slide,Navigation } from 'vue3-carousel'
import emptyImage from '@/assets/icons/emptyImage.png'

export default {
    name: 'App',
    components: {
        Carousel,
        Slide,
        Navigation,
        VerticalItemsCard
    },
    props: {
        items: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            emptyImage,
            settings: {
                itemsToShow: 4,
                wrapAround: 'true',
                snapAlign: "start    ",
                transition: "500",
                pauseAutoplayOnHover: true,
                itemsToScroll: 1,
                dir: "rtl",
            },
            breakpoints: {
                0: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },

                640: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
                2000: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                },
            }
        }

    }
}
</script>
