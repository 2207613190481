<template>
    <div class="w-full flex flex-col mt-6   items-center gap-12">
        <div   class="w-3/4  ">
            <ShopDetailsAuth/>
        </div> 
        <div  class="w-3/4">
            <ShopFilter />
        </div>
        <div  class="w-3/4" v-if="generalCategory === 'المواد'">
            <ShopProductsAuth/>
        </div>
        <div class="w-3/4" v-if="generalCategory === 'الخدمات'">
            <ShopServicesAuth/>
        </div>
    </div>
</template>

<script>
import ShopDetailsAuth from './sections/ShopDetailsAuth.vue';
import ShopServicesAuth from './sections/ShopServicesAuth.vue';
import ShopFilter from './sections/ShopFilter.vue';
import { useFilterStore } from '@/stores'
import { storeToRefs } from 'pinia';
import ShopProductsAuth from './sections/ShopProductsAuth.vue';


export default {
    setup() {
        const filterStore = useFilterStore();
        return {
            generalCategory: storeToRefs(filterStore).generalCategory,
        };
    },
    components: {
    ShopFilter,
    ShopDetailsAuth,
        ShopProductsAuth,
        ShopServicesAuth
}
}
</script>

