export function shortenString(str, maxLength = 10) {
  if (str.length > maxLength) {
    return str.substr(0, maxLength - 1) + '...';
  }
  return str;
}

export function currencyNameByCode(code) {
  switch (code) {
    case 'IQD':
      return 'د.ع'
    case 'USD':
      return '$'
    default:
      return ''
  }
}
