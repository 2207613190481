import { defineStore } from 'pinia';
import api from '@/api';

export const useAdsStore = defineStore({
    id: 'ads',

    state: () => ({
        ads: [],
        error: null,
        loading: false,
    }),

    actions: {
        async fetchAds() {
            this.loading = true;
            try {
                const ads = await api.getAds();
                this.ads = ads.data;
                this.error = null;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
    },
});
