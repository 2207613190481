<template>
    <div class="w-full flex justify-center items-center">
        <label v-if="label" :for="id" class="w-1/4 block text-sm font-medium text-gray-700 break-words">
            {{ label }}
        </label>
        <div :class="[label ? 'w-3/4' : 'w-full']">
            <div class="relative">
                <input :id="id" :maxlength="maxLength" autocomplete="off" :disabled="disabled" @keydown.enter="onEnter" valid="false"
                    :value="modelValue" @input="onInput" @blur="checkValidate" :type="type" :placeholder="placeholder"
                    :class="[
                        extendClasses,
                        'form-input block py-2 px-3 border w-full bg-white text-gray-900 rounded-full shadow-sm focus:outline-none focus:border-gray-900 sm:text-sm',
                        !validationErrors.length || 'border-red-danger focus:border-red-danger',
                        disabled ? 'border-gray-300' : 'border-gray-600'
                    ]" />
                <div v-if="maxLength"
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400 text-sm">
                    {{ modelValue.length }} / {{ maxLength }}
                </div>
            </div>
            <p v-for="validationError in validationErrors" :key="validationError" class="mx-3 mt-1 text-sm text-red-danger">
                *{{ validationError }}
            </p>
        </div>
    </div>
</template>

  
<script>
export default {
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        maxLength: {
            type: [Number, String],
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        extendClasses: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        validate: {
            type: Function,
            required: false,
            default: () => { },
        },
        onEnter: {
            type: Function,
            required: false,
            default: () => { },
        },
        validationErrors: {
            type: Array,
            required: false,
            default: new Array(),
        }
    },

    methods: {
        onInput(event) {
            this.$emit("update:modelValue", event.target.value);
        },
        checkValidate(event) {
            this.validate(event.target.value);
        },
    },

};
</script>