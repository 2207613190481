import { defineStore } from 'pinia'
import api from '@/api';
import { useFilterStore } from './FilterStore';
import router from '@/router';
const STORAGE_KEY = "auth_token";
export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        authToken: localStorage.getItem(STORAGE_KEY) || "",
        id: '',
        name: '',
        phoneNumber: '',
        oldPhoneNumber: '',
        government: { id: '', name: '' },
        area: { id: '', name: '' },
        nearestPoint: '',
        details: '',
        otbCode: '',
        password: '',
        repeatPassword: '',
        isPhoneNumberHasWhatsapp: true,
        isPhoneNumberVerified: false,
        facebook: '',
        instagram: '',
        whatsapp: '',
        images: [],
        shopProducts: [],
        shopServices: [],
        removedImage: "",
        error: null,
        loading: false,
        filter: useFilterStore(),
        pagination: {
            currentPage: 0,
            firstPageUrl: '',
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            lastPage: 0,
            perPage: 0,
            total: 0,
        }
    }),

    getters: {
        isAuthenticated: (state) => !!state.authToken,
    },

    actions: {

        async signup() {
            this.loading = true;
            this.error = null;
            this.isPhoneNumberHasWhatsapp ? this.whatsapp = this.phoneNumber : "";
            const images = this.images.map(image => image.file);
            this.error = null;
            try {
                const res = await api.signup(this.phoneNumber, this.otbCode, this.password, this.name, String(this.government.id), String(this.area.id), this.nearestPoint, this.details, images[0], this.facebook, this.instagram, this.whatsapp);
                this.setAuthToken(res.data.access_token)
                const shop = res.data.user;
                this.id = shop.id;
                this.name = shop.name;
                this.phoneNumber = shop.phone;
                this.government.id = shop.province_id;
                this.area.id = shop.neighborhood_id;
                this.nearestPoint = shop.landmark;
                this.details = shop.details;
                this.facebook = shop.facebook;
                this.instagram = shop.instagram;
                this.whatsapp = shop.whatsapp;
                this.images = shop.user_image?.map((image) => ({ src: image.url, file: "" })) || [];
                window.location.href = "/myShop"
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "phone required":
                                return "رقم الهاتف مطلوب";
                            case "code required":
                                return "كود التحقق مطلوب";
                            case "password required":
                                return "كلمة المرور مطلوبة";
                            case "name required":
                                return "الاسم مطلوب";
                            case "province required":
                                return "المحافظة مطلوبة";
                            case "neighborhood required":
                                return "المنطقة مطلوبة";
                            case "phone taken":
                                return "رقم الهاتف مستخدم";
                            case "wrong OTP":
                                return "كود التحقق غير صحيح";
                            default:
                                return message;
                        }

                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else if (JSON.parse(err.request?.response)?.message === "wrong OTP") {
                    this.error = "كود التحقق غير صحيح";
                    throw new Error(JSON.parse(err.request?.response)?.message === "wrong OTP");
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },

        async updateShopWithChangedPhoneNumber() {
            const images = this.images
                .filter(image => image.file && image.file !== "")
                .map(image => image.file);
            let {
                phoneNumber,
                otbCode,
                name,
                government,
                area,
                nearestPoint,
                details,
                facebook,
                instagram,
                isPhoneNumberHasWhatsapp,
                removedImage
            } = this;
            this.loading = true;
            this.error = null;
            isPhoneNumberHasWhatsapp ? this.whatsapp = phoneNumber : "";
            try {
                await api.updateShopWithChangedPhoneNumber(phoneNumber, otbCode, name, String(government.id), String(area.id), nearestPoint, details, images[0], facebook, instagram, this.whatsapp, removedImage);
                router.push('/myShop')
                this.error = null;
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "phone required":
                                return "رقم الهاتف مطلوب";
                            case "code required":
                                return "كود التحقق مطلوب";
                            case "password required":
                                return "كلمة المرور مطلوبة";
                            case "name required":
                                return "الاسم مطلوب";
                            case "province required":
                                return "المحافظة مطلوبة";
                            case "neighborhood required":
                                return "المنطقة مطلوبة";
                            case "phone taken":
                                return "رقم الهاتف مستخدم";
                            case "wrong OTP":
                                return "كود التحقق غير صحيح";
                            default:
                                return message;
                        }
                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else if (JSON.parse(err.request?.response)?.message === "wrong OTP") {
                    this.error = "كود التحقق غير صحيح";
                    throw new Error(JSON.parse(err.request?.response)?.message === "wrong OTP");
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },
        async updateShopWithoutChangedPhoneNumber() {
            const images = this.images
                .filter(image => image.file && image.file !== "")
                .map(image => image.file);
            let {
                phoneNumber,
                name,
                government,
                area,
                nearestPoint,
                details,
                facebook,
                instagram,
                isPhoneNumberHasWhatsapp,
                removedImage
            } = this;
            this.loading = true;
            this.error = null;
            isPhoneNumberHasWhatsapp ? this.whatsapp = phoneNumber : "";
            try {
                await api.updateShopWithoutChangedPhoneNumber(phoneNumber, name, String(government.id), String(area.id), nearestPoint, details, images[0], facebook, instagram, this.whatsapp, removedImage);
                router.push('/myShop')
                this.error = null;
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "name required":
                                return "الاسم مطلوب";
                            case "province required":
                                return "المحافظة مطلوبة";
                            case "neighborhood required":
                                return "المنطقة مطلوبة";
                            case "phone taken":
                                return "رقم الهاتف مستخدم";
                            default:
                                return message;
                        }
                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },

        async login() {
            this.loading = true;
            this.error = null;
            try {
                const res = await api.login(this.phoneNumber, this.password);
                this.setAuthToken(res.data.access_token)
                const shop = res.data.user;
                this.id = shop.id;
                this.name = shop.name;
                this.phoneNumber = shop.phone;
                this.government.name = shop.province.name;
                this.government.id = shop.province.id;
                this.area.name = shop.neighborhood.name;
                this.area.id = shop.neighborhood.id;
                this.nearestPoint = shop.landmark;
                this.details = shop.details;
                this.facebook = shop.facebook;
                this.instagram = shop.instagram;
                this.whatsapp = shop.whatsapp;
                this.images = shop.user_image?.map((image) => ({ src: image.url, file: "" })) || [];
                this.error = null;
                window.location.href = "/myShop"
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async getShop() {
            this.loading = true;
            this.error = null;
            try {
                const res = await api.getAuthShop();
                const shop = res.data.user;
                this.id = shop.id;
                this.name = shop.name;
                this.phoneNumber = shop.phone;
                this.oldPhoneNumber = shop.phone;
                this.government.name = shop.province.name;
                this.government.id = shop.province.id;
                this.area.name = shop.neighborhood.name;
                this.area.id = shop.neighborhood.id;
                this.nearestPoint = shop.landmark ?? '';
                this.details = shop.details ?? '';
                this.facebook = shop.facebook ?? '';
                this.instagram = shop.instagram ?? '';
                this.isPhoneNumberHasWhatsapp = shop.whatsapp ? true : false;
                this.whatsapp = shop.whatsapp ?? '';
                this.images = shop.user_image?.map((image) => ({ src: image.url, file: "", id: image.id })) || [];
                this.error = null;
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async logout() {
            this.loading = true;
            this.error = null;
            try {
                await api.logout(this.phone, this.password);
                this.clearAuthToken();
                this.clearShopDetails()
                localStorage.clear(STORAGE_KEY)
                window.location.href = "/login"
            } catch (err) {
                
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        async sendOtp() {
            this.loading = true;
            this.error = null;
            try {
                await api.requestOtp(this.phoneNumber);
            } catch (err) {
                if (err.message === 'Network Error') {
                    // Network error
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => message === "phone taken" ? "رقم الهاتف مستخدم بالفعل." : message);
                    this.error = "تحقق من الحقول :  " + errorMessages.join(", ");
                    throw new Error("تحقق من الحقول : " + errorMessages.join(", "));
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },
        async resetPasswordSendOtp() {
            this.loading = true;
            this.error = null;
            try {
                await api.resetPasswordRequestOtp(this.phoneNumber);
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "phone required":
                                return "رقم الهاتف مطلوب";
                            case "phone taken":
                                return "رقم الهاتف مستخدم";
                            default:
                                return message;
                        }

                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },
        addImage(image) {
            this.removedImage = this.images[0]?.id || this.removedImage;
            this.images[0] = []
            this.images[0] = image
        },
        removeImage(index = 0) {
            this.removedImage = this.images[0]?.id;
            this.images.splice(index, 1);
        },

        setAuthToken(token) {
            this.authToken = token;
            localStorage.setItem(STORAGE_KEY, token);
        },
        clearAuthToken() {
            this.authToken = null;
            localStorage.removeItem(STORAGE_KEY);
        },
        isAuth() {
            return localStorage.getItem(STORAGE_KEY) ? true : false
        },
        async checkOtp() {
            this.loading = true;
            this.error = null;
            try {
                const res = await api.checkOtp(this.phoneNumber, this.otbCode);
                this.setAuthToken(res.data.access_token)
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();

                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "phone required":
                                return "رقم الهاتف مطلوب";
                            case "code required":
                                return "كود التحقق مطلوب";
                            case "wrong OTP":
                                return "كود التحقق غير صحيح";
                            default:
                                return message;
                        }

                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else if (JSON.parse(err.request?.response)?.message === "wrong OTP") {
                    this.error = "كود التحقق غير صحيح";
                    throw new Error(JSON.parse(err.request?.response)?.message === "wrong OTP");
                }
                else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },
        async setNewPassword() {
            this.loading = true;
            this.error = null;
            try {
                await api.setNewPassword(this.password);
            } catch (err) {
                // Network error
                if (err.message === 'Network Error') {
                    this.error = "حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.";
                    throw new Error('حدث خطأ في الشبكة. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى.');
                } else if (JSON.parse(err.request?.response)?.errors) {
                    // Error with specific fields
                    const errorData = JSON.parse(err.request.response);
                    let errorMessages = Object.values(errorData.errors).flat();
                    errorMessages = errorMessages.map((message) => {
                        switch (message) {
                            case "phone required":
                                return "رقم الهاتف مطلوب";
                            case "code required":
                                return "كود التحقق مطلوب";
                            case "password required":
                                return "كلمة المرور مطلوبة";
                            case "phone taken":
                                return "رقم الهاتف مستخدم";
                            default:
                                return message;
                        }

                    });
                    this.error = "تحقق من الحقول : " + errorMessages.join(", ");
                    throw new Error('تحقق من الحقول :' + errorMessages.join(", "));
                } else {
                    // Other error
                    this.error = "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.";
                    throw new Error('حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا.');
                }
            } finally {
                this.loading = false;
            }
        },
        clearShopDetails() {
            this.id = '';
            this.name = '';
            this.phoneNumber = '';
            this.government = { id: '', name: '' };
            this.area = { id: '', name: '' };
            this.nearestPoint = '';
            this.details = '';
            this.isPhoneNumberVerified = '';
            this.password = '';
            this.repeatPassword = '';
            this.isPhoneNumberHasWhatsapp = '';
            this.facebook = '';
            this.instagram = '';
            this.whatsapp = '';
            this.images = [];
            this.shopProducts = [];
            this.shopServices = [];
            this.error = null;
            this.loading = false;
            this.filter = useFilterStore(),
                this.pagination = {
                    currentPage: 0,
                    firstPageUrl: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    lastPage: 0,
                    perPage: 0,
                    total: 0,
                };
        }
    },
})
