<template>
    <div  v-if="error" class="w-full pt-16 flex justify-center ">
        <ErrorResponse  />
    </div>
    <div v-if="loading && !error" class="w-full pt-16 flex justify-center ">
        <LoadingCircle  class=" h-10 " />
    </div>
    <div v-if="!loading && !error" class="w-full justify-center flex pt-0 pb-10  sm:py-20 ">
        <div class=" w-full sm:w-3/4  flex flex-col-reverse items-center   lg:flex-row lg:items-start gap-5   ">
            <div class=" rounded-lg  flex flex-col gap-2 w-11/12 sm:3/4 lg:w-1/2 justify-around ">
                <div class=" flex flex-col gap-4  border-b border-gray-900 pb-3">
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary   ">اسم المادة</div>
                        <div class="w-4/6 text-gray-900 font-bold ">{{ name }}</div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التصنيف الرئيسي</div>
                        <div class=" text-gray-900 font-bold  break-words  ">
                            {{ mainCategory.name }}
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التصنيف الفرعي</div>
                        <div class=" text-gray-900 font-bold  break-words  ">
                            {{ subCategory.name }}
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">التفاصيل</div>
                        <div class="  text-gray-900 font-bold break-words ">{{ details }}</div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">السعر</div>
                        <div class=" text-gray-900 font-bold  break-words  ">
                            {{ price + " " + currency.name }}
                        </div>
                    </div>
                </div>
                <div v-if="shop.image" class="rounded-lg  flex justify-center  p-3  ">
                    <img class=" aspect-[21/9] object-contain overflow-hidden " alt="shop Image" :src="shop.image">
                </div>
                <div class=" flex flex-col gap-4  ">
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary   ">اسم المعرض</div>
                        <div class="w-4/6 text-gray-900 font-bold ">{{ shop.name }}</div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between">العنوان</div>
                        <div class=" text-gray-900 font-bold  break-words  ">{{ shop.government + '/' + shop.area + '/' +
                            shop.nearestPoint }}
                        </div>
                    </div>
                    <div class="flex  w-full">
                        <div class="w-2/6 text-yellow-primary justify-between ">تفاصيل المعرض</div>
                        <div class="  text-gray-900 font-bold break-words ">{{ shop.details }}</div>
                    </div>
                    <div v-if="shop.phoneNumber"  @click="()=>{navigator.clipboard.writeText(shop.phoneNumber)}"
                         class="bg-yellow-primary w-full rounded-full p-2 flex items-center px-5 cursor-pointer ">
                        <div class="w-1/6 sm:w-2/6  ">الهاتف</div>
                        <div class="w-3/6 sm:w-2/6 ">{{ shop.phoneNumber }}</div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            نسخ الرقم
                            <img src="@/assets/icons/phone.svg" alt="">
                        </div>
                    </div>
                    <a v-if="shop.facebook" :href="'https://' + shop.facebook " target="_blank"
                        class="bg-[#2383E3] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  "> حساب الفيسبوك</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/facebookOutline.svg" alt="facebook icon">
                        </div>
                    </a>
                    <a v-if="shop.instagram" :href="'https://' + shop.instagram " target="_blank"
                        class="bg-[#9F35AD] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  ">حساب الانستكرام</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/instaOutline.svg" alt="insta icon">
                        </div>
                    </a>
                    <a v-if="shop.whatsapp" :href="'https://wa.me/964'+shop.whatsapp.slice(1)" target="_blank"
                        class="bg-[#43BE1F] text-white w-full rounded-full p-2 flex items-center px-5 ">
                        <div class="w-2/6  ">واتساب</div>
                        <div class="w-2/6"></div>
                        <div class=" w-2/6 flex justify-end items-center gap-3">
                            افتح
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp icon">
                        </div>
                    </a>
                </div>
            </div>
            <div class="w-full sm:w-1/2   bg-gray-100 rounded-xl pt-20 p-10 sm:pt-10  ">
                <ImagesSliders v-if="images.length > 0" :images="images.map(image => image.src)" />
                <ImagesSliders v-if="images.length == 0"
                    :images="[emptyImage, emptyImage, emptyImage, emptyImage, emptyImage]" />
            </div>
        </div>
    </div>
</template>


<script>
import { onMounted } from 'vue';
import { useProductStore } from '@/stores';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import ImagesSliders from '@/components/sliders/ImagesSliders.vue';
import emptyImage from '@/assets/icons/emptyImage.png';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';

export default {
    setup() {
        const productStore = useProductStore();
        const route = useRoute();
        onMounted(async () => {
            await productStore.fetchProduct(route.params.id);
            productStore.currencyNameFromCurrencyId();
        });
        return {
            name: storeToRefs(productStore).name,
            details: storeToRefs(productStore).details,
            price: storeToRefs(productStore).price,
            mainCategory: storeToRefs(productStore).mainCategory,
            subCategory: storeToRefs(productStore).subCategory,
            images: storeToRefs(productStore).images,
            shop: storeToRefs(productStore).shop,
            currency:storeToRefs(productStore).currency,
            error: storeToRefs(productStore).error,
            loading: storeToRefs(productStore).loading,
        };
    },
    data() {
        return {
            emptyImage
        };
    },
    components: { ImagesSliders, LoadingCircle, ErrorResponse }
};
</script>