<template>
    <div class=" flex flex-col gap-5">
        <TextField id="shopName" :maxLength="40" label="اسم المعرض" disabled="true" placeholder="اسم المعرض"
            v-model="authStore.name" />
        <TextField id="phone" label="هاتف" :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
            placeholder="هاتف" v-model="authStore.phoneNumber" />
        <DropDown id="whatsapp" label="هل رقم الهاتف مرتبط بحساب واتسب؟" v-model="authStore.isPhoneNumberHasWhatsapp"
            :listItems="[{ id: true, name: 'نعم ، الرقم مرتبط بحساب واتساب' }, { id: false, name: 'لا ، الرقم غبر مرتبط بحساب واتساب' }]" />
        <TextField id="facebook" :validate="validateFacebook" :validationErrors="errorMessage.facebook"
            label="رابط حساب الفيسبوك" placeholder="اكتب هنا" v-model="authStore.facebook" />
        <TextField id="instagram" :validate="validateInstagram" :validationErrors="errorMessage.instagram"
            label="رابط حساب الانستكرام" placeholder="اكتب هنا" v-model="authStore.instagram" />
        <div class="flex gap-2 w-full ">
            <StanderButton :disabled="false" :onClick="nextStep"
                class=" w-2/3 text-sm sm:text-base  self-center bg-yellow-primary hover:bg-yellow-dark ">
                التالي
            </StanderButton>
            <StanderButton :disabled="false" :onClick="previousStep"
                class=" w-1/3 text-sm sm:text-base  self-center bg-gray-900 hover:bg-black text-gray-100">
                السابق
            </StanderButton>
        </div>

    </div>
</template>
  
<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { phoneNumberRule, requiredRule, validateInstagramUrl, validateFacebookUrl } from '../../../assets/validation'
import StanderButton from "@/components/buttons/StanderButton.vue";
import DropDown from "@/components/DropDown.vue";
export default {
    components: { TextField, StanderButton, DropDown },
    setup() {
        const authStore = useAuthStore();
        return { authStore }
    },
    data() {
        return {
            errorMessage: {
                name: [],
                phoneNumber: [],
                facebook: [],
                instagram: [],
            },
            isValid: {
                name: false,
                phoneNumber: false,
                instagram: false,
                facebook: false,
            }
        }
    },

    methods: {
        nextStep() {
            this.validatePhoneNumber(this.authStore.phoneNumber);
            this.validateName(this.authStore.name);
            this.validateFacebook(this.authStore.facebook);
            this.validateInstagram(this.authStore.instagram);
            (this.isValid.name &&
                this.isValid.phoneNumber && this.isValid.instagram && this.isValid.facebook) ?
                this.$emit('changeStep', 4) : ""
        },
        previousStep() {
            this.$emit('changeStep', 2);
        },
        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
        validateName(value) {
            this.errorMessage.name = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.name.push(requiredErrorMessage)
            this.errorMessage.name.length ? this.isValid.name = false : this.isValid.name = true
        },
        validateInstagram(value) {
            this.errorMessage.instagram = [];
            if (value === null || value === undefined || value === '') {
                this.isValid.instagram = true
                return 0
            }
            if (value.includes('https://')) {
                value = value.replace('https://', '')
                this.authStore.instagram = value
            }
            const isInstagramUrlErrorMessage = validateInstagramUrl(value);
            if (isInstagramUrlErrorMessage !== null) {
                this.errorMessage.instagram.push(isInstagramUrlErrorMessage)
                this.isValid.instagram = false
                return 0
            }
            this.isValid.instagram = true
        },
        validateFacebook(value) {
            this.errorMessage.facebook = [];
            if (value === null || value === undefined || value === '') {
                this.isValid.facebook = true
                return 0
            }
            if (value.includes('https://')) {
                value = value.replace('https://', '')
                this.authStore.facebook = value
            }
            const isFacebookUrlErrorMessage = validateFacebookUrl(value);
            if (isFacebookUrlErrorMessage !== null) {
                this.errorMessage.facebook.push(isFacebookUrlErrorMessage)
                this.isValid.facebook = false
                return 0
            }
            this.isValid.facebook = true
        },

    },
};
</script>