import axios from './axios';

const serviceApi = {
    async getUserServices(userId, productMainCategory = "", productSubCategory = "", productName = "") {
        const data = { user_id: userId, srv_main_cat_id: productMainCategory, srv_sub_cat_id: productSubCategory, srv_name: productName }
        return await axios.post('/service/getUserService', data);
    },
    async getServiceById(id) {
        const data = { service_id: id }
        return await axios.post('/service/get', data);
    },
    async getAuthServiceById(id) {
        const data = { service_id: id }
        return await axios.post('/service/owner/get', data);
    },
    async addService(name, srv_main_cat_id, srv_sub_cat_id, details, currency, price, images) {
        const data = new FormData();
        data.append('name', name);
        data.append('srv_main_cat_id', srv_main_cat_id);
        data.append('srv_sub_cat_id', srv_sub_cat_id);
        data.append('details', details);
        data.append('currency', currency);
        data.append('price', price);

        images.forEach((image, index) => {
            data.append(`image[${index}]`, image);
        });

        return await axios.post('/service/store', data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    },
    async updateService(id, name, srv_main_cat_id, srv_sub_cat_id, details, currency, price, images, remove) {
        const data = new FormData();
        data.append('service_id', id);
        data.append('name', name);
        data.append('srv_main_cat_id', srv_main_cat_id);
        data.append('srv_sub_cat_id', srv_sub_cat_id);
        data.append('details', details);
        data.append('currency', currency);
        data.append('price', price);
        images.forEach((image, index) => {
            data.append(`image[${index}]`, image);
        });
        remove.length ? remove.forEach((removedImageId, index) => {
            data.append(`remove[${index}]`, removedImageId);
        }) : "";

        return await axios.post('/service/update', data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    },
    async deleteService(service_id) {
        const data = { service_id: service_id }
        return await axios.post('/service/delete', data);
    },
    async getAuthUserServices(productMainCategory = "", productSubCategory = "", productName = "") {
        const data = { srv_main_cat_id: productMainCategory, srv_sub_cat_id: productSubCategory, srv_name: productName }
        return await axios.post('service/owner/getUserService', data);
    },
    async activeService(serviceId) {
        const data = { service_id: serviceId }
        return await axios.post('/service/activate', data);
    },
    async deactiveService(serviceId) {
        const data = { service_id: serviceId }
        return await axios.post('/service/deactivate', data);
    }
}
export default serviceApi;

