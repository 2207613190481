<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  w-[widthRatio]  gap-4" :class="extendClasses">
        <slot />
    </div>
</template>
  
<script>
export default {
    name: 'CardGrid',
    props: {
        extendClasses: {
            type: String,
            required: false,
            default:""
        },
    },
};
</script>
