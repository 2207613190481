<template>
    <Carousel id="gallery" :items-to-show="1" snapAlign="start" :wrap-around="false" v-model="currentSlide" dir="rtl">
        <Slide v-for="(image, index) in images" :key="index">
            <div class="carousel__item w-full h-full">
                <img :src="image" alt="item image" class="aspect-[1/1] h-full w-full object-contain overflow-hidden">
            </div>
        </Slide>
        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>



    <Carousel id="thumbnails" :items-to-show="4" snapAlign="center" v-model="currentSlide" ref="carousel" dir="rtl">
        <Slide v-for="(image, index) in images" :key="index">
            <div class="carousel__item  w-full h-full" @click="slideTo(index)">
                <img :src="image" alt="item image" class="aspect-[1/1] h-full w-full p-1 object-contain overflow-hidden">
            </div>
        </Slide>
        <template #addons>

        </template>
    </Carousel>
</template>
  
  

  
<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import './carousel.css'

export default defineComponent({
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination
    },
    props: {
        images: {
            type: Array,
            require: true,
        },
    },

    data: () => ({
        currentSlide: 0,
    }),
    methods: {
        slideTo(val) {
            this.currentSlide = val
        },
    },
})
</script>
