<template>
    <div class="flex flex-col gap-5 justify-center ">
        <h1 class="pr-3">
            قم بانشاء رمز المرور الخاص بك
        </h1>
        <TextField id="password" :validate="validatePassword" :validationErrors="errorMessage.password"
            placeholder="رمز المرور" v-model="authStore.password" />
        <TextField id="phone" :validate="validatePasswordCopy" :validationErrors="errorMessage.repeatPassword"
            placeholder="اعد كتابة رمز المرور" v-model="authStore.repeatPassword" />
        <div>
            <p class=" text-center text-gray-900 ">يفضل ان لا يقل رمز المرور عن ٨ احرف او ارقام</p>
            <p class=" text-center text-gray-900 ">ستحتاج رمز المرور عند تسجيل الدخول في المرة القادمة</p>
        </div>
        <div class="flex gap-2 w-full ">
            <StanderButton :disabled="false" :onClick="nextStep"
                class=" w-2/3 text-sm sm:text-base  self-center bg-yellow-primary hover:bg-yellow-dark ">
                التالي
            </StanderButton>
            <StanderButton :disabled="false" :onClick="previousStep"
                class=" w-1/3 text-sm sm:text-base self-center bg-gray-900 hover:bg-black text-gray-100">
                السابق
            </StanderButton>
        </div>
    </div>
</template>
  
<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { isCopyRule, requiredRule } from '../../../assets/validation'
import StanderButton from "@/components/buttons/StanderButton.vue";
export default {
    components: { TextField, StanderButton },
    setup() {
        const authStore = useAuthStore();
        return { authStore }
    },
    data() {
        return {
            errorMessage: {
                password: [],
                repeatPassword: [],
            },
            isValid: {
                password: false,
                repeatPassword: false,
            }
        }
    },

    methods: {
        nextStep() {
            this.validatePassword(this.authStore.password);
            this.validatePasswordCopy(this.authStore.repeatPassword);

            (this.isValid.password && this.isValid.repeatPassword) ?
                this.$emit('changeStep', 3) : ""
        },
        previousStep() {
            this.$emit('changeStep', 1);
        },
        validatePassword(value) {
            this.errorMessage.password = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.password.push(requiredErrorMessage)
            this.errorMessage.password.length ? this.isValid.password = false : this.isValid.password = true
        },
        validatePasswordCopy(value) {
            this.errorMessage.repeatPassword = [];
            const requiredErrorMessage = requiredRule(value);
            const isCopyErrorMessage = isCopyRule(value, this.authStore.password);
            requiredErrorMessage === null ? "" : this.errorMessage.repeatPassword.push(requiredErrorMessage)
            isCopyErrorMessage === null ? "" : this.errorMessage.repeatPassword.push(isCopyErrorMessage)
            this.errorMessage.repeatPassword.length ? this.isValid.repeatPassword = false : this.isValid.repeatPassword = true
        },


    },
};
</script>