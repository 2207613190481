<template>
  <div class="w-full  flex justify-center items-center">
    <label v-if="label" :for="id" class=" w-1/4 block text-sm font-medium text-gray-700 break-words ">
      {{ label }}
    </label>
    <div class="relative w-full flex" >
      <img class="w-2 h-2 absolute top-0 left-0 m-4 pointer-events-none" src="@/assets/icons/arrowDown.svg"
        alt="arrowDown">
      <select v-model="selectedValue" @change="onSelectChange" :disabled="disabled"
        class="rounded-full pr-5 pl-10 py-2 appearance-none focus:outline-none font-bold " :class="[extendClasses,
          !validationErrors.length || ('border-red-danger focus:border-red-danger'),
          label ? ('w-3/4') : ('w-full')]">
        <option :value="emptyCustomObject" disabled selected>{{ defaultText }}</option>
        <option v-if="selectAllText" :value="emptyCustomObject">{{ selectAllText }}</option>
        <option v-for="item in listItems" :key="item.id" :value="getOptionValue(item)">
          {{ item.name }}
        </option>
      </select>
    </div>
    <p v-for="validationError in validationErrors" :key="validationError" class="mx-3 mt-1  text-sm text-red-danger">
      *{{ validationError }}
    </p>
  </div>
</template>
  
<script>
export default {
  props: {
    listItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    defaultText: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Object , Number],
      default: "",
    },
    extendClasses: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueProperty: {
      type: String,
      required: false,
      default: "id",
    },
    objectValueProperties: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectAllText: {
      type: String,
      required: false,
      default: "",
    },
    validate: {
      type: Function,
      required: false,
      default: () => { },
    },
    validationErrors: {
      type: Array,
      required: false,
      default: new Array(),
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    emptyCustomObject() {
      let customObject = {};
      if (this.objectValueProperties.length) {
        this.objectValueProperties.forEach(property => {
          customObject[property] = "";
        });
        return customObject;
      }
      return ""
    },
  },
  methods: {
    onSelectChange() {
      this.$emit("onSelect", this.selectedValue);
      this.checkValidate();
    },
    checkValidate() {
      this.validate(this.selectedValue);
    },
    getOptionValue(item) {
      if (this.objectValueProperties.length) {
        let customObject = {};
        this.objectValueProperties.forEach(property => {
          customObject[property] = item[property];
        });
        return customObject;
      }
      return item[this.valueProperty];
    },
  },
};
</script>