<template>
    <div>
        <div class="w-full flex flex-col  items-center">
           
            <div class="w-3/4 mt-6 ">
                <GeneralFilter />
            </div>
            <div class="w-3/4" v-if="generalCategory === 'المواد'" >
                <DividerDependOnGeneralCategories :generalCategory="generalCategory" />
                <ProductsSection />
            </div>
            <div class="w-3/4" v-if="generalCategory === 'الخدمات'" >
                <DividerDependOnGeneralCategories :generalCategory="generalCategory" />
                <ServicesSection />
            </div>
            <div class="w-3/4" v-if="generalCategory === 'المعارض'" >
                <DividerDependOnGeneralCategories :generalCategory="generalCategory" />
                <ShopsSection />
            </div>

        </div>
    </div>
</template>

<script>
import ProductsSection from './sections/ProductsSection.vue';
import  ServicesSection from './sections/ServicesSection.vue';
import  ShopsSection from './sections/ShopsSection.vue';
import DividerDependOnGeneralCategories from './sections/DividerDependOnGeneralCategories.vue';
import { useFilterStore } from '@/stores'
import { storeToRefs } from 'pinia';
import GeneralFilter from './sections/GeneralFilter.vue';
export default {
    setup() {
        const filterStore = useFilterStore();
        return {
            generalCategory: storeToRefs(filterStore).generalCategory,
        };
    },
    components: {
    ProductsSection,
    ServicesSection,
    DividerDependOnGeneralCategories,
    ShopsSection,
    GeneralFilter
}
}
</script>