import axios from './axios';

const productApi = {
    async getUserProducts(userId, productMainCategory = "", productSubCategory = "", productName = "") {
        const data = { user_id: userId, prod_main_cat_id: productMainCategory, prod_sub_cat_id: productSubCategory, prod_name: productName }
        return await axios.post('/product/getUserProduct', data);
    },
    async getProductById(id) {
        const data = { product_id: id }
        return await axios.post('/product/get', data);
    },
    async getAuthProductById(id) {
        const data = { product_id: id }
        return await axios.post('/product/owner/get', data);
    },
    async addProduct(name, prod_main_cat_id, prod_sub_cat_id, details, currency, price, images) {
        const data = new FormData();
        data.append('name', name);
        data.append('prod_main_cat_id', prod_main_cat_id);
        data.append('prod_sub_cat_id', prod_sub_cat_id);
        data.append('details', details);
        data.append('currency', currency);
        data.append('price', price);
        images.forEach((image, index) => {
            data.append(`image[${index}]`, image);
        });
        return await axios.post('/product/store', data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    },
    async updateProduct(id, name, prod_main_cat_id, prod_sub_cat_id, details, currency, price, images , remove) {
        const data = new FormData();
        data.append('product_id', id);
        data.append('name', name);
        data.append('prod_main_cat_id', prod_main_cat_id);
        data.append('prod_sub_cat_id', prod_sub_cat_id);
        data.append('details', details);
        data.append('currency', currency);
        data.append('price', price);
        images.forEach((image, index) => {
            data.append(`image[${index}]`, image);
        });
        remove.length ? remove.forEach((removedImageId, index) => {
            data.append(`remove[${index}]`, removedImageId);
        }) : [];
        return await axios.post('/product/update', data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    },
    async deleteProduct(product_id) {
        const data = { product_id: product_id }
        return await axios.post('/product/delete', data);
    },
    async getAuthUserProducts(productMainCategory = "", productSubCategory = "", productName = "") {
        const data = { prod_main_cat_id: productMainCategory, prod_sub_cat_id: productSubCategory, prod_name: productName }
        return await axios.post('/product/owner/getUserProduct', data);
    },
    async activeProduct(productId) {
        const data = { product_id: productId }
        return await axios.post('/product/activate', data);
    },
    async deactiveProduct(productId) {
        const data = { product_id: productId }
        return await axios.post('/product/deactivate', data);
    }
}

export default productApi;

