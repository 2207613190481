<template>
    <div class=" md:p-10 rounded-xl">
        <div v-if="!loading && !error" class="flex flex-col gap-2">
            <ItemCardAuth v-for="item in items" :key="item.id" :id="item.id"
                :imageSrc="item.first_prod_image?.url || emptyImage" :shopName="item.user.name"
                :mainCategory="item.prod_main_cat?.name || ''" :subCategory="item.prod_sub_cat?.name || ''"
                :itemName="item.name" :shopLocation="item.user.landmark" :price="item.price" :isActive="item.active"
                :deleteItem="handleItemToDelete" :isActiveToggle="toggleProduct" :editPageRoute="'/product/update/' + item.id"
                :currency="currencyNameByCode(item.currency)" />
        </div>
        <DilogTrueOrFalse :isOpen="showDialog" title="حذف" trueText="حذف" falseText="الغاء" :message=" 'هل انت متاكد بأنك تريد حذف هذه المادة' +'  '  + productToDelete.name " @optionSelected="handleOptionSelected"
            @close="showDialog = false" />
        <div class="w-full flex justify-center">
            <EmptyResponse v-if="!loading && !error && !items.length" />
            <LoadingCircle v-if="loading && !error" class=" h-10 " />
            <ErrorResponse v-if="error" />
        </div>
        <div class="mt-20">
            <PaginationItems v-if="(items.length > 0 && !error) || loading" :currentPage="pagination.currentPage"
                :totalPages="pagination.lastPage" :pagesToShow="pagination.lastPage" @update:current-page="fetchSpecificPage" />
        </div>
    </div>
</template>

<script >
import PaginationItems from '@/components/PaginationItems.vue';
import { onMounted, watch } from 'vue';
import { useItemsStore, useFilterStore, useProductStore } from '@/stores'
import { storeToRefs } from 'pinia';
import emptyImage from '@/assets/icons/emptyImage.png';
import ItemCardAuth from '@/components/cards/ItemCardAuth.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import { currencyNameByCode } from '@/assets/js/HelperFunction'
import DilogTrueOrFalse from '@/components/DilogTrueOrFalse.vue';
export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        const productStore = useProductStore();
        onMounted(async () => {
            await itemsStore.fetchAuthUserProducts(1);
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchAuthUserProducts(1);
                }
            }
        );
        const fetchSpecificPage = async (page) => {
            await itemsStore.fetchAuthUserProducts(page);
        }


        const toggleProduct = async (id) => {
            const item = itemsStore.products.find(item => item.id == id);
            if (!parseInt(item.active)) {
                await productStore.activeProduct(id)
            } else {
                await productStore.deactiveProduct(id)
            }
            productStore.error ? "" : item.active = parseInt(item.active) ^ 1
        }
        const deleteProduct = async (id) => {
            await productStore.deleteProduct(id)
            if (!(productStore.error)) {
                const index = itemsStore.products.findIndex(item => item.id == id);
                itemsStore.products.splice(index, 1);
            }
        }
        return {
            fetchSpecificPage,
            toggleProduct,
            deleteProduct,
            items: storeToRefs(itemsStore).products,
            pagination: storeToRefs(itemsStore).pagination,
            loading: storeToRefs(itemsStore).loading,
            DeleteLoading: storeToRefs(productStore).loading,
            DeleteError: storeToRefs(productStore).error,
            error: storeToRefs(itemsStore).error,
        };
    },
    data() {
        return {
            emptyImage,
            showDialog: false,
            productToDelete:{},
        };
    },
    methods: {
        currencyNameByCode,
        handleOptionSelected(option) {
            if (option) {
                this.deleteProduct(this.productToDelete.id);
            }
            this.showDialog = false;
        },
        handleItemToDelete(id , name) {
            this.showDialog = true;
            this.productToDelete.id = id;
            this.productToDelete.name = name;
        }
    },
    components: { PaginationItems, ItemCardAuth, ErrorResponse, LoadingCircle, EmptyResponse, DilogTrueOrFalse }
}
</script>