<template>
    <div class="relative aspect-[3/1]   w-full h-full rounded overflow-hidden shadow-lg   ">
        <img :src="imageUrl" alt="Adds image" class="   h-full w-full  !object-contain bg-gray-300 ">
        <a :href="adUrl" target="_blank" class="w-full h-full" >
            <StanderButton
                class=" bg-yellow-primary hover:bg-yellow-dark absolute bottom-6  right-10 text-xs py-0 px-6 sm:bottom-3  sm:right-5 sm:text-sm sm:py-0 sm:px-8 md:py-[0.05rem] md:px-10 md:text-base md:bottom-4  md:right-8 lg:bottom-6  lg:right-14 lg:py-1 lg:px-16  2xl:bottom-8  2xl:right-16  2xl:py-2 2xl:px-28  2xl:text-2xl ">
                {{ buttonTitle }}
            </StanderButton>
        </a>
    </div>
</template>
  
<script >
import StanderButton from '../buttons/StanderButton.vue';
export default {
    components: {
    StanderButton,},
    props: {
        imageUrl: {
            type: String,
            required: true,
            default: "",
        },
        adUrl: {
            type: String,
            required: true,
            default: "",
        },
        buttonTitle: {
            type: String,
            required: true,
            default: "",
        }
    }
}
</script>
  