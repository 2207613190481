<template>
    <router-link :to="route">
        <div class="flex flex-row aspect-[6/3] w-full rounded-3xl overflow-hidden shadow-lg">
            <div class="aspect-[1/1] w-1/3 md:w-1/2 object-contain flex justify-center items-center " >
                <img  :alt="imageAlt" :src="imageSrc">
            </div>
            <div class="  w-2/3 md:w-1/2 grid  grid-rows-8 flex-1 bg-gray-100 ">
                <div class=" text-sm md:text-xl row-span-2 font-bold px-3 self-end text-gray-900   overflow-hidden ">{{itemName.length > 10 ? itemName.substr(0, 10 - 1) + '...' :itemName }}    </div>
                <div class="text-sm md:text-lg  px-3 row-span-2 self-start text-gray-600 overflow-hidden ">{{shopName.length > 10 ? shopName.substr(0, 10 - 1) + '...' :shopName  }}</div>
                <div class="text-xs   md:text-sm row-span-2 px-3  self-start text-gray-600 overflow-hidden "> {{shopLocation.length > 15 ? shopLocation.substr(0, 15 - 1) + '...' :shopLocation  }}</div>
                <div v-if="price" class=" text-sm  md:text-lg row-span-2 px-4 pb-1  font-bold justify-self-end self-end text-gray-600 overflow-hidden ">{{(price.length > 4 ? price.substr(0, 4 - 1) + '..' :price) + '  ' + currency }}
                </div>
            </div>
        </div>
    </router-link>
</template>
 
<script >
export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
            default: "",
        },
        imageAlt: {
            type: String,
            required: false,
            default: "",
        },
        shopName: {
            type: String,
            required: true,
            default: "",
        },
        itemName: {
            type: String,
            required: true,
            default: "",
        },
        shopLocation: {
            type: String,
            required: true,
            default: "",
        },
        price: {
            type: String,
            required: true,
            default: "",
        },
        route: {
            type: String,
            required: false,
            default: "",
        },
        currency: {
            type: String,
            required: false,
            default: "",
        }
    }
}
</script>
