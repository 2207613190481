<template>
    <div class=" flex flex-col gap-5 w-full   ">
        <TextField id="phone" label="هاتف"  :validate="validatePhoneNumber" :validationErrors="errorMessage.phoneNumber"
            placeholder="هاتف" disabled="true" v-model="phoneNumber" />
        <div class="flex gap-2 md:gap-5 pt-10 w-full ">
            <TextField id="phoneVerification" :validate="validateOtp" :validationErrors="errorMessage.otp"
                placeholder="اكتب رمز التاكيد" v-model="otpCode" />
            <StanderButton :onClick="handleSendOtp" :disabled="isSendingOtp" class=" w-1/2 md:w-1/3 text-sm sm:text-base  self-center bg-gray-900"
                :class="isSendingOtp ? (' text-gray-600') : ('  hover:bg-black text-gray-100')">
                اعادة ارسال
            </StanderButton>
        </div>
        <div>
            <p class="text-xs text-gray-600 text-center">سيتم ارسال رمز التاكيد الى رقم الهاتف المسجل</p>
        </div>
        <div v-if="error" >
            <p class="text-xs text-red-danger text-center"> {{ error}} </p>
        </div>

        <div class="flex gap-2 w-full pt-10 ">
            <StanderButton :onClick="nextStep" class=" w-2/3 text-sm sm:text-base  self-center bg-yellow-primary hover:bg-yellow-dark ">
                تأكيد
            </StanderButton>
            <LoadingCircle v-if="loading && !error" class="h-8" />
            <StanderButton :onClick="previousStep" class=" w-1/3 text-sm sm:text-base  self-center bg-gray-900 hover:bg-black text-gray-100">
                السابق
            </StanderButton>
        </div>

        <a href="/" class="text-center">
            <button class="text-center underline hover:underline-offset-2 transition-all duration-300 ease-in-out' "
                :onclick="clearShopDetails"> الغاء</button>
        </a>

    </div>
</template>
  
<script>
import { useAuthStore } from "./../../../stores";
import TextField from './../../../components/inputComponent/TextField.vue'
import { phoneNumberRule, requiredRule } from '../../../assets/validation'
import StanderButton from "@/components/buttons/StanderButton.vue";
import LoadingCircle from "@/components/response/LoadingCircle.vue";
import { storeToRefs } from "pinia";
export default {
    components: { TextField, StanderButton, LoadingCircle },
    setup() {
        const authStore = useAuthStore();
        return {
            phoneNumber:storeToRefs(authStore).phoneNumber,
            otpCode:storeToRefs(authStore).otbCode,
            loading: storeToRefs(authStore).loading,
            error: storeToRefs(authStore).error,
            checkOtp: authStore.checkOtp,
            sendOtp: authStore.resetPasswordSendOtp,
            clearShopDetails: authStore.clearShopDetails,
        }
    },

    data() {
        return {
            errorMessage: {
                phoneNumber: [],
                otp: []
            },
            isValid: {
                phoneNumber: false,
                otp: false,
            },
            isSendingOtp: false,
        }
    },

    methods: {
        async nextStep()  {
            this.validatePhoneNumber(this.phoneNumber);
            this.validateOtp(this.otpCode);
             
            if (this.isValid.phoneNumber && this.isValid.otp   ) {
                await this.checkOtp()
                this.error?this.errorMessage.otp.push('تاكد من الرمز'):this.$emit('changeStep', 3);
            }
        },
        previousStep() {
            this.$emit('changeStep', 1);
        },
        validatePhoneNumber(value) {
            this.errorMessage.phoneNumber = [];
            const requiredErrorMessage = requiredRule(value);
            const isPhoneNumberErrorMessage = phoneNumberRule(value);
            if (requiredErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(requiredErrorMessage);
                this.isValid.phoneNumber = false
                return 0
            } else if (isPhoneNumberErrorMessage !== null) {
                this.errorMessage.phoneNumber.push(isPhoneNumberErrorMessage)
                this.isValid.phoneNumber = false
                return 0
            }
            this.isValid.phoneNumber = true
        },
        validateOtp(value) {
            this.errorMessage.otp = [];
            const requiredErrorMessage = requiredRule(value);
            requiredErrorMessage === null ? "" : this.errorMessage.otp.push(requiredErrorMessage)
            this.errorMessage.otp.length ? this.isValid.otp = false : this.isValid.otp = true
        },
        async handleSendOtp() {
            if (!this.isSendingOtp) {
                this.isSendingOtp = true;
                this.sendOtp();
                setTimeout(() => {
                    this.isSendingOtp = false;
                }, 30000);
            }
        },
    },
};
</script>