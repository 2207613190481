<template>
    <StanderButton class=" w-10 h-10 bg-gray-300 text-gray-900   " :imageSrc="notificationIconDark"
        @click="toggleNotificationList">
    </StanderButton>
    <transition name="fade">
        <div v-if="isOpen"
            class="bg-gray-300 absolute left-[50%] translate-x-[-50%] lg:translate-x-[0] lg:left-0  md:mr-10 mt-2  w-[95vw]  sm:w-80 max-h-64  overflow-y-auto  rounded-lg shadow-lg  pointer-events-auto z-50">
            <div class="relative">
                <div class="absolute top-0 right-0 mt-4 mr-4">
                    <button @click="toggleNotificationList" class="focus:outline-none">
                        <i class="fas fa-times text-gray-500"></i>
                    </button>
                </div>

                <ul v-if="loading && !error" class="flex justify-center items-center p-8 ">
                    <LoadingCircle class=" h-8 " />
                </ul>
                <ul v-if="!loading && !error && !notifications.length" class="flex justify-center items-center p-8 ">
                    <CustomEmptyResponse>
                        لايوجد اشعارات
                    </CustomEmptyResponse>
                </ul>
                <ul v-if="!loading && error" class="flex justify-center items-center p-8 ">
                    <CustomErrorResponse>
                        يوجد خطا في الاشعارات
                    </CustomErrorResponse>
                </ul>
                <ul class="divide-y divide-gray-400">
                    <li v-for="(notification, index) in notifications" :key="index" class="p-2">
                        {{ notification.body }}
                    </li>

                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
import { useNotificationStore } from '@/stores';
import { storeToRefs } from 'pinia';
import StanderButton from '../buttons/StanderButton.vue';
import notificationIconDark from '@/assets/icons/notificationIconDark.svg';
import LoadingCircle from '../response/LoadingCircle.vue';
import CustomEmptyResponse from '../response/CustomEmptyResponse.vue';
import CustomErrorResponse from '../response/CustomErrorResponse.vue';


export default {
    setup() {
        const notificationStore = useNotificationStore();
        return {
            notifications: storeToRefs(notificationStore).notifications,
            isOpen: storeToRefs(notificationStore).isOpen,
            toggleNotificationList: notificationStore.toggleNotificationList,
            loading: storeToRefs(notificationStore).loading,
            error: storeToRefs(notificationStore).error,
        };
    },
    data() {
        return {
            notificationIconDark
        }
    },
    components: { StanderButton, LoadingCircle, CustomEmptyResponse, CustomErrorResponse }
}
</script>