<template>
    <div class="w-full flex flex-col mt-6   items-center gap-12">
        <div class=" w-5/6 md:w-3/4  ">
            <ShopDetails />
        </div>
        <div class="w-3/4">
            <ShopFilter />
        </div>
        <div class="w-3/4" v-if="generalCategory === 'المواد'">
            <ShopProducts />
        </div>
        <div class="w-3/4" v-if="generalCategory === 'الخدمات'">
            <ShopServices />
        </div>
    </div>
</template>

<script>
import ShopDetails from './sections/ShopDetails.vue';
import ShopFilter from './sections/ShopFilter.vue';
import ShopProducts from './sections/ShopProducts.vue';
import ShopServices from './sections/ShopServices.vue';
import { useFilterStore } from '@/stores'
import { storeToRefs } from 'pinia';


export default {
    setup() {
        const filterStore = useFilterStore();
        return {
            generalCategory: storeToRefs(filterStore).generalCategory,
        };
    },
    components: {
        ShopDetails,
        ShopFilter,
        ShopProducts,
        ShopServices
    }
}
</script>

