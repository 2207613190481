<template>
    <div class="bg-gray-900  sm:pt-5 sm:pb-10">
        <AdsSliders v-if="ads.length>0" :ads="ads" />
    </div>
</template>

<script>
import AdsSliders from '@/components/sliders/AdsSliders.vue';
import {useAdsStore} from '@/stores'
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';

export default {
    setup() {
        const adsStore = useAdsStore();
        onMounted(async () => {
            await adsStore.fetchAds();
        });

        return {
            ads: storeToRefs(adsStore).ads || [],
            loading: storeToRefs(adsStore).loading,
            error: storeToRefs(adsStore).error,
        };
    },
    components: { AdsSliders },
}
</script>