<template>
    <ServiceProductContainer  v-if="!loading && !error">
        <HorizontalItemCard   v-for="item in items" :route="'/service/' + item.id" :key="item.id" :imageSrc="item.first_srv_image?.url  || emptyImage"
            :shopName="item.user.name" :itemName="item.name" :shopLocation="item.user.landmark" :price="item.price" :currency="item.currency === 'IQD'?'د.ع':'$' " />
    </ServiceProductContainer>
    <div class="w-full flex justify-center">
            <EmptyResponse  v-if="!loading && !error && !items.length" />
            <LoadingCircle v-if="loading && !error" class=" h-10 " />
            <ErrorResponse v-if="error" />
        </div>
    <div class="mt-20">
        <PaginationItems v-if="(items.length > 0 && !error) || loading" :currentPage="pagination.currentPage" :totalPages="pagination.lastPage" :pagesToShow="pagination.lastPage"
            @update:current-page="fetchSpecificPage" />
    </div>
</template>

<script >
import ServiceProductContainer from '@/components/containers/ServiceProductContainer.vue';
import HorizontalItemCard from '@/components/cards/HorizontalItemCard.vue';
import PaginationItems from '@/components/PaginationItems.vue';
import { onMounted, watch } from 'vue';
import { useFilterStore, useItemsStore } from '@/stores'
import { storeToRefs } from 'pinia';
import emptyImage from '@/assets/icons/emptyImage.png';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';


export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        onMounted(async () => {
            await itemsStore.fetchAllServices();
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchAllServices();
                }
            }
        );
        const fetchSpecificPage = async (page) => {
            await itemsStore.fetchAllServices(page);
        }

        return {
            fetchSpecificPage,
            items: storeToRefs(itemsStore).services,
            pagination: storeToRefs(itemsStore).pagination,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
            itemsFlg: storeToRefs(itemsStore).itemsFlg,
        };
    },
    data() {
        return {
            emptyImage
        };
    },
    components: { ServiceProductContainer, HorizontalItemCard, PaginationItems, ErrorResponse, LoadingCircle, EmptyResponse }
}
</script>