<template>
    <ServiceProductContainer v-if="!loading && !error">
        <ShopCard v-for="item in items" :key="item.id" :id="item.id" :imageSrc="item.user_image[0]?.url || emptyImage"
            :shopName="item.name" :location="item.landmark" />
    </ServiceProductContainer>
    <div class="w-full flex justify-center">
        <EmptyResponse  v-if="!loading && !error && !items.length" />
        <LoadingCircle v-if="loading && !error" class=" h-10 " />
        <ErrorResponse v-if="error" />
    </div>
    <div class="mt-20">
        <PaginationItems v-if="(items.length > 0 && !error) || loading" :currentPage="pagination.currentPage"
            :totalPages="pagination.lastPage" :pagesToShow="pagination.lastPage" @update:current-page="fetchSpecificPage" />
    </div>
</template>

<script >
import ServiceProductContainer from '@/components/containers/ServiceProductContainer.vue';
import ShopCard from '@/components/cards/ShopCard.vue';
import PaginationItems from '@/components/PaginationItems.vue';
import { onMounted } from 'vue';
import { useItemsStore, useFilterStore } from '@/stores'
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import emptyImage from '@/assets/icons/emptyImage.png';
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';


export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore()
        onMounted(async () => {
            await itemsStore.fetchAllShops();
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchAllShops();
                }
            }
        );
        const fetchSpecificPage = async (page) => {
            await itemsStore.fetchAllShops(page);
        }
        return {
            fetchSpecificPage,
            items: storeToRefs(itemsStore).shops,
            pagination: storeToRefs(itemsStore).pagination,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
        };
    },
    data() {
        return {
            emptyImage
        };
    },
    components: { ServiceProductContainer, ShopCard, PaginationItems, ErrorResponse, LoadingCircle, EmptyResponse }
}
</script>