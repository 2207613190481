<template>
    <nav class="w-full bg-gray-900 flex justify-center  fixed z-50 ">
        <!-- Large screen navigation -->
        <div class="hidden lg:flex justify-between items-center w-3/4 gap-4 h-16  ">
            <div class="flex gap-4">
                <StanderButton
                    class="w-32 bg-yellow-primary hover:bg-yellow-dark text-gray-900 flex justify-center items-center p-2 gap-2"
                    :onclick="navigateToHomePage">
                    <img src="@/assets/icons/grayLogo.svg" alt="logo" />
                    الرئيسية
                </StanderButton>
                <NavButton v-if="!($route.name === 'items')" label="المواد" :icon="productIconDark"
                    :onclick="() => { setGeneralCategory('المواد'), navigateToItemsPage() }" />
                <NavButton v-if="!($route.name === 'items')" label="الخدمات" :icon="serviceIconDark"
                    :onclick="() => { setGeneralCategory('الخدمات'), navigateToItemsPage() }" />
                <NavButton v-if="!($route.name === 'items')" label="المعارض" :icon="shopIconDark"
                    :onclick="() => { setGeneralCategory('المعارض'), navigateToItemsPage() }" />
            </div>

            <div class="flex gap-4 items-center">
                <div class="w-42">
                    <DropDown v-if="!($route.name === 'items')" :listItems="Provinces" v-model="SelectedProvince"
                        defaultText="اختر المحافظة" selectAllText="الكل" />
                </div>
                <RouterLink to="/contact">
                    <NavButton label="تواصل معنا" :icon="personIconDark" />
                </RouterLink>

                <div class="relative">
                    <NotificationsContainer />
                </div>

                <SignUpButton :isAuth="isAuth" />
            </div>
        </div>

        <!-- Small screen navigation -->
        <div v-if="!showMenu" class="flex px-10 justify-between items-center w-full lg:hidden h-16  ">
            <StanderButton
                class="w-32 bg-yellow-primary hover:bg-yellow-dark text-gray-900 flex justify-center items-center p-2 gap-2"
                :onclick="navigateToHomePage">
                <img src="@/assets/icons/grayLogo.svg" alt="logo" />
                الرئيسية
            </StanderButton>
            <button @click="toggleMenu" class="w-6 h-6">
                <img :src="menuIcon" alt="Menu" />
            </button>
        </div>

        <!-- Small screen menu items -->
        <div v-if="showMenu" class="w-full bg-gray-900 flex justify-center items-center mt-2 xl:hidden">
            <div class="flex flex-col gap-2">

                <button @click="toggleMenu" class="w-6 h-6 self-center mb-5 ">
                    <img :src="exit" alt="Menu" />
                </button>
                <StanderButton
                    class=" bg-yellow-primary hover:bg-yellow-dark text-gray-900 flex justify-center items-center p-2 gap-2"
                    :onclick="() => { navigateToHomePage(), toggleMenu() }">
                    <img src="@/assets/icons/grayLogo.svg" alt="logo" />
                    الرئيسية
                </StanderButton>

                <NavButton v-if="!($route.name === 'items')" label="المواد" :icon="productIconDark"
                    :onclick="() => { setGeneralCategory('المواد'), navigateToItemsPage(), toggleMenu() }" />
                <NavButton v-if="!($route.name === 'items')" label="الخدمات" :icon="serviceIconDark"
                    :onclick="() => { setGeneralCategory('الخدمات'), navigateToItemsPage(), toggleMenu() }" />
                <NavButton v-if="!($route.name === 'items')" label="المعارض" :icon="shopIconDark"
                    :onclick="() => { setGeneralCategory('المعارض'), navigateToItemsPage(), toggleMenu() }" />
                <DropDown v-if="!($route.name === 'items')" :listItems="Provinces" v-model="SelectedProvince"
                    defaultText="اختر المحافظة" @onSelect="toggleMenu" selectAllText="الكل" />
                <RouterLink to="/contact" @click="toggleMenu" >
                    <NavButton label="تواصل معنا" :icon="personIconDark" class="w-full" />
                </RouterLink>
                <div class="relative self-center ">
                    <NotificationsContainer />
                </div>
                <div class="self-center" @click="toggleMenu">
                    <SignUpButton :isAuth="isAuth" />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import StanderButton from "@/components/buttons/StanderButton.vue";
import NavButton from "@/components/buttons/NavButton.vue";
import SignUpButton from "@/components/buttons/SignUpButton.vue";
import DropDown from "@/components/DropDown.vue";
import { useFilterStore, useProvincesStore, useAuthStore } from "@/stores";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import productIconDark from "@/assets/icons/productIconDark.svg";
import serviceIconDark from "@/assets/icons/serviceIconDark.svg";
import shopIconDark from "@/assets/icons/shopIconDark.svg";
import personIconDark from "@/assets/icons/personIconDark.svg";
import menuIcon from "@/assets/icons/menuIcon.svg";
import exit from "@/assets/icons/exit.svg";
import NotificationsContainer from "../notifications/NotificationsContainer.vue";
import { ref } from 'vue';

export default {
    setup() {
        const filterStore = useFilterStore();
        const provincesStore = useProvincesStore();
        const isAuth = useAuthStore().isAuth();
        const router = useRouter();
        const navigateToHomePage = () => {
            router.push("/");
        };
        const navigateToItemsPage = () => {
            router.push("/items");
        };

        onMounted(async () => {
            await provincesStore.fetchUsedProvinces();
        });
        let showMenu = ref(false);
        return {
            isAuth,
            navigateToHomePage,
            navigateToItemsPage,
            SelectedProvince: storeToRefs(filterStore).province,
            Provinces: storeToRefs(provincesStore).provinces,
            setGeneralCategory: filterStore.setGeneralCategory,
            generalCategory: storeToRefs(filterStore).generalCategory,
            showMenu: showMenu,
            toggleMenu: () => (showMenu.value = !showMenu.value),
        };
    },
    data() {
        return {
            productIconDark,
            serviceIconDark,
            shopIconDark,
            personIconDark,
            menuIcon,
            exit,
        };
    },
    components: {
        StanderButton,
        NavButton,
        SignUpButton,
        DropDown,
        NotificationsContainer,
    },
};
</script>
