<template>
    <RouterLink :to="titleUrl" >
        <div class=" h-6 sm:h-10 rounded-full bg-yellow-primary my-4  ">
            <div class="flex justify-start  items-center h-full  ">
                <div>
                    <slot name="icon"></slot>
                </div>
                <div class="text-white text-base sm:text-lg md:text-xl xl:text-2xl 2xl:text-3xl">
                    {{ title }}
                </div>
            </div>
        </div>
    </RouterLink>
</template>

<script >
import { RouterLink } from 'vue-router';

export default {
    props: {
        title: {
            type: String,
            require: false,
            default: ""
        },
        titleUrl: {
            type: String,
            require: false,
            default: ""
        }
    },
    components: { RouterLink }
}

</script>