import axios from './axios';

const categoriesApi = {
    async getServicesMainCategories() {
        return await axios.get('/serviceMainCat/getAll');
    },
    async getProductsMainCategories() {
        return await axios.get('/productMainCat/getAll');
    },
};

export default categoriesApi;
