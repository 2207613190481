<template>
    <button   v-bind="$attrs"  class="rounded-3xl  font-bold   transition ease-in-out duration-300 flex justify-around items-center p-2 "  >
        <slot />
        <img v-if="imageSrc"  :src="imageSrc" class="w-5" alt="logo">
    </button>
</template>


<script>
export default {
    props: {
        imageSrc: {
            type: String,
            required:false
        }
    }
 }
</script>
