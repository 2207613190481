<template>
<div>
    <PrimaryDivider title="المعارض" titleUrl="/items" @click="()=>{setGeneralCategory('المعارض')}"  >
                <template v-slot:icon>
                    <img src="@/assets/icons/shop.svg" class="w-4 m-2 sm:w-6 sm:m-3  md:w-8 md:m-4 " alt="star">
                </template>
            </PrimaryDivider>
    <div class="min-h-[20vh]  pt-5 pb-10 ">
        <MainContainer v-if="!loading && !error" >
            <ShopCard v-for="shop in shops " :key="shop.id" :id="shop.id" :imageSrc="shop.user_image[0]?.url || emptyImage"
                :location="shop.landmark" :shopName="shop.name" />
        </MainContainer>
        <div class="w-full flex justify-center">
            <EmptyResponse  v-if="!loading && !error && !shops.length" />
            <LoadingCircle v-if="loading && !error" class=" h-10 " />
            <ErrorResponse v-if="error" />
        </div>
    </div>
</div>
</template>

<script >
import ShopCard from '@/components/cards/ShopCard.vue';
import MainContainer from '@/components/containers/ShopsContainer.vue';
import { useItemsStore, useFilterStore } from '@/stores'
import { onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import emptyImage from '@/assets/icons/emptyImage.png'
import LoadingCircle from '@/components/response/LoadingCircle.vue';
import ErrorResponse from '@/components/response/ErrorResponse.vue';
import EmptyResponse from '@/components/response/EmptyResponse.vue';
import PrimaryDivider from '@/components/dividers/PrimaryDivider.vue';

export default {
    setup() {
        const itemsStore = useItemsStore();
        const filterStore = useFilterStore();
        onMounted(async () => {
            await itemsStore.fetchSampleShops();
        });
        watch(
            () => filterStore.province,
            async (newFilter, oldFilter) => {
                if (newFilter !== oldFilter) {
                    await itemsStore.fetchSampleShops();
                }
            }
        );
        return {
            shops: storeToRefs(itemsStore).shops,
            loading: storeToRefs(itemsStore).loading,
            error: storeToRefs(itemsStore).error,
            setGeneralCategory: filterStore.setGeneralCategory
        };
    },
    data() {
        return {
            emptyImage
        }
    },
    components: {
    ShopCard,
    MainContainer,
    LoadingCircle,
    ErrorResponse,
    EmptyResponse,
    PrimaryDivider
},
}
</script>

